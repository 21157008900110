import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 2em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const WinnerTitle = styled.h2`
  width: 100%;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 1em;
`;

export const WinnerNumber = styled.button`
  width: 100%;
  padding: 1.5em;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 65px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;
  background: var(--White, #fff);
  cursor: pointer;
  margin: 10px;
`;

export const WinnerGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;
