// src/components/WinnersCard/styles.js
import styled from 'styled-components';

export const Card = styled.div`
  background: #333;
  border-radius: 26px;
  color: white;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2.5em;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Winners = styled.div`
  padding: 16px;
`;

export const CardTitle = styled.h2`
  color: var(--Ligth-Grey, #595959);

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em;
`;

export const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
  border-radius: 82.669px;
  border: 4.108px solid var(--Gradient, #8bffdb);
`;

export const PrizeName = styled.h3`
  font-size: 16px;
  margin: 0;
`;

export const WinnerName = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const DateText = styled.p`
  font-size: 12px;
  margin-top: 0.9em;
  margin: 0;
`;

export const TrophyIcon = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
`;
