import styled from 'styled-components';

export const Container = styled.div``;
export const Title = styled.h3`
  width: 100%;
  margin-bottom: 1em;
  font-size: 18px;
  color: black;
`;
export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 8px;
  padding: 2em 2em;
  margin: auto;
`;

export const SupportButton = styled.button`
  border-radius: 15px;
  background: var(--Green, #00bc9a);
  padding: 2.5em 2em;
  margin: 5px 0;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s ease;
  font-size: 16px;
  border: 1px solid #00bc9a;
  &:hover {
    opacity: 0.9;
  }
`;

export const SupportEmailButton = styled.button`
  border-radius: 15px;
  background: var(--Green, #fff);
  padding: 2.5em 2em;

  font-size: 16px;
  margin: 5px 0;
  border: none;
  color: #595959;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  transition: all 0.3s ease;

  svg {
    font-size: 32px;
  }
  &:hover {
    opacity: 0.9;
  }
`;

export const SupportTitle = styled.h2`
  margin-bottom: 20px;
`;

export const IconButton = styled.img`
  width: 24px;
  height: 24px;
`;
