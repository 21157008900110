import styled from 'styled-components';

export const PrizeGeneratorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  border-top: 1px solid #00000010;
  margin-top: 5em;
`;

export const Title = styled.h2`
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 2em;
`;

export const TicketContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 5px; // Add padding to keep content within bounds

  input { 
    width: 100%;
  flex-grow: 1; // Allow the input to fill the space
  margin-right: 5px;
  width: 100%;
  padding: 1.3em;
  border-radius: 12px;

  font-size: 16px;
  border: 1px solid #ccc;
  background-color: white;
  }
`;

export const TicketInput = styled.input`
  width: 100%;
  flex-grow: 1; // Allow the input to fill the space
  margin-right: 5px;
  width: 100%;
  padding: 1.3em;
  border-radius: 12px;

  font-size: 16px;
  border: 1px solid #ccc;
  background-color: white;
`;

export const StepperButton = styled.button`
  padding: 5px 10px;
  border: none;
  background-color: red;
  color: #fff;

  margin-right: 5px;

  cursor: pointer;

  &:hover {
    background-color: #d4d4d4;
  }
`;

export const AddButton = styled(StepperButton)`
  width: 100%; // Set button width to 100%
  margin-top: 10px;
  border-radius: 0;
  background-color: green;
`;
export const GenerateButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #45a045;
  }
`;
