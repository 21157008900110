// RafflePrizeSet/index.js
import React, { useState } from 'react';
import { PiShieldCheckDuotone, PiTrophyDuotone } from 'react-icons/pi';

import * as S from './styles';
const RafflePrizeSet = ({ defineWinner }) => {
  const [prizeNumber, setPrizeNumber] = useState('');

  const handleInputChange = (e) => {
    setPrizeNumber(e.target.value);
  };

  const handleConfirm = async () => {
    // Logic to confirm the prize winner
    console.log('Confirmed prize number:', prizeNumber);
    await defineWinner(prizeNumber);
  };

  return (
    <S.Container>
      <S.Title>Definir o premiado</S.Title>

      <S.InputRow>
        <S.InputGroup>
          <PiTrophyDuotone />

          <S.Input
            type='text'
            value={prizeNumber}
            onChange={handleInputChange}
            placeholder='#00000000'
          />
        </S.InputGroup>
        <S.ConfirmButton onClick={handleConfirm}>
          Confirmar <PiShieldCheckDuotone />
        </S.ConfirmButton>
      </S.InputRow>
    </S.Container>
  );
};

export default RafflePrizeSet;
