// src/components/CountdownTimer/styles.js
import styled from 'styled-components';

export const TimerContainerExpired = styled.div`
  background-color: #f96961;
  padding: 2em;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 100%;
  text-align: center;
  height: 150px;
  margin: 0 auto;
`;

export const TimerContainer = styled.div`
  background-color: #000; // Dark background
  padding: 2em 2em 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 100%;
  text-align: center;

  margin: 0 auto;
`;

export const Time = styled.span`
  color: #fff;
  font-size: 15px;
  margin-bottom: 1em;
  font-weight: regular;
  display: flex;
  align-items: center;
  gap: 1em;
  margin-left: 8px;
  svg {
    font-size: 30px;
  }
`;

export const ClockIcon = styled.img`
  height: 2em; // Adjust size as needed
  width: 2em; // Adjust size as needed
  margin-right: 8px;
`;

export const ProgressBarContainer = styled.div`
  bottom: 10px; // Position below the timer text
  left: 10px;
  right: 10px;
  height: 40px; // Smaller, less prominent height
  background-color: #fff; // Semi-transparent white
  border-radius: 12px; // Rounded corners for progress bar
  padding: 0.4em;
  width: 100%;
`;

export const ProgressBar = styled.div`
  height: 100%;
  height: 32px;
  background-color: #f3ff00; // Filled color for progress
  width: 0; // Initial state with no width
  border-radius: 12px; // Rounded corners for filled part
  transition: width 1s ease-in-out; // Smooth transition for width
`;

export const Timing = styled.h2`
  color: #f3ff00;
`;
