// RaffleCreated/index.js
import React, { useEffect, useState } from 'react';
import NumberList from '../../Raffle/NumberList';
import * as S from './styles';
import { useParams } from 'react-router-dom';
import { httpServiceCms } from '../../../config/httpService';
import { APP_IMAGE_URL } from '../../../env';

const CreatedRaffleSucess = () => {
  const { id } = useParams()
  const [raffle, setRaffle] = useState({});
  const [winningTickets, setWinningTickets] = useState({})

  const handleGetWinningTickets = async (id) => {
    const { data: response } = await httpServiceCms.get(`/winning-tickets`, {
      params: {
        populate: '*',
        'filters[rifa]': id
      }
    });

    if (response) {
      setWinningTickets(response.data[0]);
    }
  }

  const handleGetDetail = async (id) => {
    const { data: response } = await httpServiceCms.get(`/rifas/${id}`, {
      params: {
        populate: '*'
      }
    });

    await handleGetWinningTickets(response.data.id)

    if (response) {
      setRaffle({
        id: response.data.id,
        ...response.data.attributes,
      });
    }
  }

  useEffect(() => {
    if (id) {
      handleGetDetail(id)
    }
  }, [id])


  const handleShowMedia = (media) => {
    if (!media) return;

    if (media.data.length > 0) {
      const image = media.data[0].attributes.url;
      return `${APP_IMAGE_URL}${image}`;
    }
  }


  const copyToClipboard = (id) => {
    const url = `${window.location.origin}/product/${id}`

    navigator.clipboard
      .writeText(url)
      .then(() => {
        // Handle successful copy action, perhaps showing a tooltip or changing button text
        console.log('Link copied to clipboard!');
      })
      .catch((err) => {
        // Handle errors, perhaps showing a tooltip or changing button text
        console.error('Failed to copy link: ', err);
      });
  };

  if(!raffle?.id || !winningTickets?.id) return null

  return (
    <S.RaffleContainer>
      <S.CelebrationMessage>🎉 Uhuul, rifa criada!</S.CelebrationMessage>
      <S.RaffleImage src={handleShowMedia(raffle.medias)} alt='Raffle' />
      <S.RaffleTitle>{raffle.title}</S.RaffleTitle>
      <S.RaffleDescription>{raffle.description}</S.RaffleDescription>

      {/* <S.RaffleDetail>
        <S.RaffleDetailText>{raffleInfo.pricePerTicket}</S.RaffleDetailText>
      </S.RaffleDetail> */}
      <S.ShareLinkContainer onClick={() => copyToClipboard(id)}>
        <S.CopyIcon>📋</S.CopyIcon>
        <S.ShareLinkText>{`${window.location.origin}/product/${id}`}</S.ShareLinkText>
      </S.ShareLinkContainer>

      <NumberList
        title={'Tickets premiados emitidos:'}
        numbers={winningTickets?.attributes?.tickets?.map(item => item.numberticket)}
      />
    </S.RaffleContainer>
  );
};

export default CreatedRaffleSucess;
