import React, { useCallback, useEffect, useState } from 'react';
import { PiCrownDuotone } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpServiceCms } from '../../config/httpService';
import { TOKEN_NAME } from '../../env';

import * as S from './styles';

const milgrau = '/assets/header/milgrau.svg';
const logo = '/assets/header/logo-kelly.svg';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({});

  const hangleGetMe = useCallback(async () => {
    try {
      const token = localStorage.getItem(TOKEN_NAME);

      if (token) {
        const { data } = await httpServiceCms.get('/users/me', {
          params: {
            populate: 'role',
          },
        });
        setUser(data);

        if (location.pathname === '/admin') {
          if (data.role.id !== 4) {
            navigate('/account/menu');
          }
        }

        if (location.pathname === '/account/menu') {
          if (data.role.id === 4) {
            navigate('/admin');
          }
        }
      }
    } catch (error) {}
  }, [location]);

  useEffect(() => {
    hangleGetMe();
  }, [navigate]);

  const handleNavigation = (path) => {
    navigate(path);
  };
  const isProductPage = location.pathname.includes('/product/');
  return (
    <>
      <S.Header>
        <S.Branding
          src={logo}
          alt='RifaMilGrau'
          onClick={() => handleNavigation('/')}
        />
        <S.Logo
          src={milgrau}
          alt='RifaMilGrau'
          onClick={() => handleNavigation('/')}
        />
      </S.Header>
      {!isProductPage && (
        <S.WelcomeText>
          <PiCrownDuotone />
          Seja bem vindo <b>{user.personal_name}</b>
        </S.WelcomeText>
      )}
    </>
  );
};

export default Header;
