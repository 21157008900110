// src/components/CountdownTimer/index.js
import React, { useEffect, useState } from 'react';
import { PiClockCountdownDuotone } from 'react-icons/pi';
import * as S from './styles';
const clockIconSrc = '/assets/icons/clock-white.svg'; // Ensure you have the clock icon

const CountdownTimer = ({
  secondsLeftToExpire,
  totalTime,
  timeExpired,
  setTimeExpired,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    setSecondsLeft(secondsLeftToExpire);
  }, [secondsLeftToExpire]);

  useEffect(() => {
    // Only start the countdown if totalSeconds is greater than 0
    if (secondsLeftToExpire > 0) {
      const timer = setInterval(() => {
        setSecondsLeft((prevSecondsLeft) => {
          const newSecondsLeft = prevSecondsLeft - 1;
          if (newSecondsLeft <= 0) {
            setTimeExpired(true); // Set timeExpired to true when countdown finishes
            clearInterval(timer); // Clear interval when countdown finishes
            return 0; // Stop the countdown
          }
          return newSecondsLeft;
        });
      }, 1000);

      // Clean up interval on component unmount
      return () => clearInterval(timer);
    }
  }, [secondsLeftToExpire]);

  // Calculate minutes and seconds from secondsLeft
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  // Calculate progress percentage
  const progressPercentage = (secondsLeft / totalTime) * 100;

  if (timeExpired) {
    return (
      <S.TimerContainerExpired>
        <S.Time>
          <S.ClockIcon src={clockIconSrc} alt='Clock' />
          Que pena, o tempo para pagamento expirou
        </S.Time>
      </S.TimerContainerExpired>
    );
  }

  return (
    <S.TimerContainer>
      <S.Time>
        <PiClockCountdownDuotone />
        Faltam apenas{' '}
        <S.Timing>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{' '}
        </S.Timing>
        minutos para pagar
      </S.Time>
      <S.ProgressBarContainer>
        <S.ProgressBar style={{ width: `${progressPercentage}%` }} />
      </S.ProgressBarContainer>
    </S.TimerContainer>
  );
};

export default CountdownTimer;
