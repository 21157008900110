import React, { useEffect, useState } from 'react';
import { PiTicketDuotone } from 'react-icons/pi';
import NumberList from '../NumberList';
import * as S from './styles';

const RaffleDetails = ({ product }) => {
  const [image, setImage] = useState('');

  const handleGetProductImage = (images) => {
    if (!images) return;

    if (images.length > 0) {
      const image = images[0].attributes.url;
      setImage(`${image}`);
    }
  };

  useEffect(() => {
    if (product.id) {
      handleGetProductImage(product?.medias?.data);
    }
  }, [product]);

  const formatToBrl = (value) => {
    const total = parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    });

    return total;
  };

  return (
    <S.SummaryContainer>
      <S.ProductInfo>
        <S.ProductImage src={image} alt='teste' />
        <S.ProductDetails>
          <S.ProductName>{product.title}</S.ProductName>
          <S.ProductDescription>{product.description}</S.ProductDescription>
        </S.ProductDetails>
      </S.ProductInfo>

      <S.TicketInfo>
        <S.TicketText>
          <PiTicketDuotone />
          {product.sold.length} tickets comprados
        </S.TicketText>
      </S.TicketInfo>
      <NumberList numbers={product.sold.map((item) => item.ticket)} />
    </S.SummaryContainer>
  );
};

export default RaffleDetails;
