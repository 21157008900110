import React, { useEffect, useState } from 'react';
import NumberList from '../../Raffle/NumberList';
import * as S from './styles';

const OrderSummary = ({ product, reserve, showTickets }) => {
  const [image, setImage] = useState('');

  const handleGetProductImage = (images) => {
    if (!images) return;

    if (images.length > 0) {
      const image = images[0].attributes.url;
      setImage(`${image}`);
    }
  };

  useEffect(() => {
    if (product.id) {
      handleGetProductImage(product?.medias?.data);
    }
  }, [product]);

  const calculatePrice = () => {
    const total = reserve.pricePaid || Number(product.price) * reserve.tickets;

    const toBrl = total.toLocaleString('pt-br', { minimumFractionDigits: 2 });

    return toBrl;
  };

  const transformToBrl = (value) => {
    const total = parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    });

    return total;
  };

  return (
    <S.SummaryContainer>
      <S.ProductInfo>
        <S.ProductImage src={image} alt={product.name} />
        <S.ProductDetails>
          <S.ProductName>{product.title}</S.ProductName>
          <S.ProductDescription>{product.description}</S.ProductDescription>
        </S.ProductDetails>
      </S.ProductInfo>

      <S.PriceCalculation>
        <S.QuantityPrice>
          R${' '}
          {reserve?.pricePaid
            ? transformToBrl(reserve?.pricePaid)
            : product.price}{' '}
          x {reserve.tickets}
        </S.QuantityPrice>
        <S.TotalPrice>R$ {calculatePrice()}</S.TotalPrice>
        <S.TicketInfo>
          <S.TicketText>{reserve.tickets} bilhetes</S.TicketText>
        </S.TicketInfo>
      </S.PriceCalculation>

      {showTickets && (
        <NumberList
          title={'Seus tickets foram emitidos:'}
          numbers={['00000001']}
        />
      )}
    </S.SummaryContainer>
  );
};

export default OrderSummary;
