import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { httpServiceMiddleware } from '../../config/httpService';
import moment from 'moment';

moment.locale('pt-br');

const WinnersCard = ({ winner }) => {
  const [winners, setWinners] = useState([])

  const getWinners = async () => {
    const { data } = await httpServiceMiddleware.get('/rifas/last/trophies');

    setWinners(data)
  }

  useEffect(() => {
    getWinners()
  }, [])

  return !!winner?.id && (
    <S.Winners>
      <S.CardTitle>Ganhadores 🏆</S.CardTitle>

      {/* {winners.map((winner, index) => ( */}
        <S.Card>
          {/* <S.ProfilePicture src={winner.profilePicture} alt="Winner's Profile" /> */}
          <div>
            <S.PrizeName>{winner?.rifa}</S.PrizeName>
            <S.WinnerName>{winner?.personal_name} - #{winner?.ticket}</S.WinnerName>
            <S.WinnerName>Telefone: {winner?.phone}</S.WinnerName>
            <S.DateText>{moment(winner?.createdAt).format("D [de] MMMM [de] YYYY")}</S.DateText>
          </div>
          <S.TrophyIcon src='/assets/icons/trophy.svg' alt='Trophy' />
        </S.Card>
      {/* ))} */}
    </S.Winners>
  );
};

export default WinnersCard;

// 942797