// PublishButton.js
import React from 'react';
import * as S from './styles';
const publishIcon = '/assets/icons/publish.svg';
const PublishButton = ({ onClick, type, disabled }) => {
  return (
    <S.PublishButton type={type} onClick={onClick} disabled={disabled}>
      Publicar agora
      <S.PublishIcon src={publishIcon} />
    </S.PublishButton>
  );
};

export default PublishButton;
