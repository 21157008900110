import styled, { keyframes } from 'styled-components';

export const shimmerAnimation = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const ShimmerEffect = styled.div`
  animation: ${shimmerAnimation} 2s linear infinite;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 100%;
  height: 100%;
  position: relative;
`;

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

export const Badge = styled.div`
  border-radius: 15.366px;
  border: 0.42px solid var(--Green, #eb1943);
  background: #eb1943;
  padding: 4px 16px;
  position: absolute;
  top: 1.4em;
  left: 1.4em;
  font-size: 14px;
  z-index: 2;
  color: #fff;
  position: absolute;
  animation: ${blink} 1s linear infinite;
`;

export const RaffleCard = styled.div`
  border-radius: 21px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4.773px 28.636px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2.1659717559814453px);

  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  &:hover {
    scale: 1.01;
  }
`;
export const BuyIcon = styled.img`
  width: 28px;
  height: auto;
`;
export const ProductImage = styled.img`
  width: 100%;
  height: 340px;
  object-fit: cover;
`;

export const RaffleInfo = styled.div`
  padding: 2em;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const RaffleInfoInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ColumnLeft = styled.div`
  width: 100%;
`;
export const ColumnRight = styled.div`
  width: 20%;
`;

export const RaffleTitle = styled.h2`
  font-size: 17px;
  color: #000;
  margin-bottom: 8px;
  text-align: left;
  line-height: 1.3em;
`;

export const RaffleDescription = styled.p`
  color: #666;
  letter-spacing: -0.4px;
  font-size: 14px;
  line-height: 1.2em;
  text-align: left;
`;

export const SmallCaps = styled.h6`
  font-size: 12px;
  text-align: right;
  font-weight: 400;
`;

export const Currency = styled.p`
  font-size: 14px;
  font-weight: 400;
`;
export const Price = styled.div`
  color: #000;
  text-align: right;
  font-weight: bold;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  display: flex;
  font-size: 24px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  justify-content: center;
  align-items: center;
  padding: 2em 2em;
`;

export const Title = styled.h3`
  width: 100%;
  font-size: 18px;
  color: black;
`;
export const BuyButton = styled.button`
  background: var(--Green, #00bc9a);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  width: 100%;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  min-height: 65px;
  cursor: pointer;
  box-shadow: 0px 1px 36px -2px rgb(0 188 154 / 44%);
  transition: all 0.3s ease;
  border-radius: 15px;

  svg {
    font-size: 25px;
  }
  &:hover {
    background-color: #00bc9a;
    color: #fff;
    border: 1px solid #00bc9a;

    scale: 1.02;
  }
`;
