// src/components/Raffle/RaffleDetail.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import WinnerInfo from '../WinnerInfo';
const imageRaffle = '/assets/raffles/rifabmw.png';
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.2; }
`;

const Badge = styled.div`
  border-radius: 15.366px;
  border: 0.42px solid var(--Green, #eb1943);
  background: #eb1943;
  padding: 4px 16px;
  position: absolute;
  top: 2em;
  left: 2em;
  font-size: 14px;
  z-index: 2;
  color: #fff;
  animation: ${blink} 1s linear infinite;
`;

const DetailContainer = styled.div`
  margin: auto;
  padding: 0 0 0em 0;
  max-width: 640px;
`;

const Title = styled.h1`
  font-size: 21px;
  margin-bottom: 0.3em;
  color: #fff;
`;

const Description = styled.p`
  font-size: 13px;
  color: #fff;
`;

export const Currency = styled.span`
  font-size: 12px;
  margin-right: 2px;
  color: #000;
  font-weight: 500;
`;

const RafflePricing = styled.p`
  font-size: 28px;
  text-align: right;
  color: #000;
  font-weight: 700;
`;

const PriceInfo = styled.div`
  font-size: 24px;
  color: #333;
  background: #fff;
  width: 26%;
  padding: 0.4em;
  gap: 0.2em;
  text-align: right;
  display: flex;
  border-radius: 13px;
  align-items: flex-end;
  flex-direction: column;
`;

const Strong = styled.strong`
  color: #000;
  text-align: right;
  width: 100%;
  font-size: 11px;
  font-weight: 400;
`;

const ImageGalleryContainer = styled.div`
  margin-top: 0px;
  position: relative;
`;

const ThumbnailList = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.2em;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 400px;
  border: none;
  object-fit: cover;

  &:hover {
    border-color: #aaa;
  }
`;

const FullImage = styled.img`
  width: 100%;
  max-width: 600px;
`;

export const ProductBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 1em 2em 2em;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`;
export const Infos = styled.div`
  padding-right: 2em;
  width: 90%;
`;

const RaffleDetail = ({ raffle }) => {
  const winnerData = {
    name: 'Ledyson Santos Almeida',
    phone: '+55(73) 9****** 0',
    email: 'le**********@gmail.com',
    address: 'Itacaré-BA',
    message: '#11561',
  };

  console.log('raffle', raffle);
  return (
    <DetailContainer>
      <ImageGalleryContainer>
        {/* <Badge> ⚡ Acabou de sair</Badge> */}
        <ProductBar>
          <WinnerInfo winner={winnerData} />
          <Infos>
            <Title>{raffle.title}</Title>
            <Description>{raffle.description}</Description>
          </Infos>
        </ProductBar>
        {raffle.medias.data.length && (
          <ThumbnailList>
            {raffle.medias.data.map((image, index) => (
              // <Thumbnail key={index} src={image?.attributes?.url} />
              <Thumbnail key={index} src={imageRaffle} />
            ))}
          </ThumbnailList>
        )}
      </ImageGalleryContainer>
    </DetailContainer>
  );
};

export default RaffleDetail;
