// src/components/LastTickets/styles.js
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.2; }
`;

export const Badge = styled.div`
  border-radius: 15.366px;
  border-radius: 15.773px;
  border: 0.431px solid #fc3663;
  background: #fc3663;
  box-shadow: 4px 4px 5px 0px rgba(252, 54, 99, 0.2);
  backdrop-filter: blur(7.12797212600708px);
  color: #fff;
  padding: 4px 16px;
  width: fit-content;
  top: 2em;
  left: 2em;
  font-size: 14px;
  z-index: 1;

  animation: ${blink} 1s linear infinite;
`;

export const TicketCard = styled.div`
  background: white;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 3em;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 230px;
  flex-direction: column;
  margin-bottom: 0.8rem;
`;

export const TicketCardBefore = styled.div`
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: #00000080;
  pointer-events: none; // Para não interferir na interação do usuário
`;
export const Title = styled.h3`
  width: 100%;
  color: var(--Ligth-Grey, #595959);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1em;
`;

export const LastTickets = styled.div`
  padding: 2em;
  cursor: pointer;
`;
export const Header = styled.div`
  padding: 0;

  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
  height: 30px;
  z-index: 1;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.4em;
`;

export const ProgressBar = styled.div`
  border-radius: 15.773px;
  border: 0.431px solid #fc3663;
  background: var(
    --Bar,
    linear-gradient(
      90deg,
      #40bce2 1.6%,
      #d984bb 42.52%,
      #f35d82 70.33%,
      #fc3663 99.97%
    )
  );
  box-shadow: 4px 4px 5px 0px rgba(252, 54, 99, 0.2);

  height: 100%;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease;
  height: 100%;
`;

export const TicketInfo = styled.div`
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Data = styled.div``;
export const PriceData = styled.div``;

export const TicketTitle = styled.h3`
  font-size: 18px;
  color: #fff;
  margin-bottom: 4px;
`;

export const TicketDescription = styled.p`
  color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const SubPrice = styled.div`
  font-size: 12px;
  text-align: right;
  color: #fff;
  font-weight: normal;
`;

export const Price = styled.div`
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  text-align: right;
`;
