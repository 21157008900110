import { PiDotsNineDuotone } from 'react-icons/pi';

import React, { useState } from 'react';
import { PiUserSwitchDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { httpServiceCms } from '../../../config/httpService';
import * as S from './styles';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleReset = async () => {
    const { email } = credentials;
    try {
      if (!email) {
        throw new Error('Preencha todos os campos');
      }

      setLoading(true);

      Swal.fire({
        title: 'Aguarde',
        text: 'Estamos enviando um email para você',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      await httpServiceCms.post('/auth/forgot-password', {
        email,
      });

      Swal.fire('Sucesso', 'Email enviado com sucesso', 'success');

      navigate('/');
    } catch (error) {
      Swal.fire(
        'Oops',
        'Ocorreu um erro ao enviar o email, tente novamente',
        'error',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleReset();
    }
  };

  return (
    <S.Body>
      <S.HeadingBox>
        <PiUserSwitchDuotone />
        <S.Title>Esqueci minha senha</S.Title>
        <S.SubTitle>
          Digite seu email abaixo <br /> para solicitar uma nova senha
        </S.SubTitle>
      </S.HeadingBox>
      <S.Forms>
        <S.Input
          name='email'
          value={credentials.email}
          onChange={handleChange}
          placeholder='E-mail'
          icon='envelope'
          onKeyDown={handleKeyDown}
        />

        <S.SendButton onClick={handleReset} disabled={loading}>
          Solicitar nova senha
          <PiDotsNineDuotone />
        </S.SendButton>
      </S.Forms>
    </S.Body>
  );
};

export default ForgotPassword;
