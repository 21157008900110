import * as S from './styles';
const Image4 = '/assets/footer/gaming.png ';
const Image1 = '/assets/footer/pix.png ';
const Image3 = '/assets/footer/curacao.png ';

const Image2 = '/assets/footer/18anos.png ';

const Footer = () => {
  return (
    <S.Container>
      <S.FooterLinks>
        <S.ImageWrapper>
          <S.Image src={Image1} alt='Image description' />
          <S.Image src={Image2} alt='Image description' />
          <S.Image src={Image3} alt='Image description' />
          <S.Image src={Image4} alt='Image description' />
        </S.ImageWrapper>

        <S.Copyright>
          A plataforma www.rifamilgrau.com é operada pela RFM Flash B.V.,
          empresa devidamente constituída e licenciada pelas autoridades
          reguladoras de Curaçao e certificada sob o número de registro 165698.
        </S.Copyright>

        <S.FooterLink href='/terms-conditions'>
          Termos de Uso e Condições
        </S.FooterLink>
      </S.FooterLinks>
    </S.Container>
  );
};

export default Footer;
