import React, { useCallback } from 'react';

import * as S from './styles';

const Block = ({ setBlocks, listBlocks }) => {
  const handleQuantityChange = useCallback(
    (index, value) => {
      const updatedTickets = [...listBlocks];
      updatedTickets[index].quantity = value;
      setBlocks(updatedTickets);
    },
    [listBlocks],
  );

  const handlePriceChange = useCallback(
    (index, value) => {
      const updatedTickets = [...listBlocks];
      updatedTickets[index].price = value;
      setBlocks(updatedTickets);
    },
    [listBlocks],
  );

  return (
    <S.PrizeGeneratorContainer>
      <S.Title>Bloco promocional 💰</S.Title>
      {listBlocks.map((item, index) => (
        <S.TicketContainer key={index}>
          <S.TicketInput
            type='text'
            onChange={(e) => handleQuantityChange(index, e.currentTarget.value)}
            placeholder='Quantidade'
          />

          <S.TicketInput
            value={item.price}
            placeholder='Valoor'
            onChange={(e) => {
              handlePriceChange(index, e.currentTarget.value);
            }}
            type='text'
          />
        </S.TicketContainer>
      ))}
    </S.PrizeGeneratorContainer>
  );
};

export default Block;
