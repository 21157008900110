import ProfileSettings from '../../../components/Admin/ProfileSettings';
import BackButton from '../../../components/BackButton';
import * as S from './styles';

const UserSettings = () => {
  return (
    <>
      <BackButton />
      <S.Container>
        <ProfileSettings />
      </S.Container>
    </>
  );
};

export default UserSettings;
