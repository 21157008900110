import React, { useEffect, useState } from 'react';
import { BsTicket } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

const ListRaffles = ({ product, category }) => {
  console.log('product', product);
  const [image, setImage] = useState('');
  const navigate = useNavigate();

  const handleGetProductImage = (images) => {
    if (!images) return;

    if (images.length > 0) {
      const image = images[0].attributes.url;
      setImage(`${image}`);
    }
  };

  useEffect(() => {
    if (product.id) {
      handleGetProductImage(product?.medias?.data);
    }
  }, [product]);

  const handleClickDetail = (id) => {
    navigate(`/raffles/details/${id}`);
  };

  const handleClickCheckout = (product) => {
    console.log(product.reference);
    navigate(`/checkout/${product.reference}`);
  };

  const formatToBrl = (value) => {
    const total = parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    });

    return total;
  };

  return (
    <S.SummaryContainer
      onClick={() =>
        category === 'sold' ? handleClickDetail(product.id) : null
      }
    >
      {/* <S.SummaryTitle>Resumo do seu pedido</S.SummaryTitle> */}
      <S.ProductInfo>
        <S.ProductImage src={image} alt={product.name} />
        <S.ProductDetails>
          <S.ProductName>{product.title}</S.ProductName>
          <S.ProductDescription>{product.description}</S.ProductDescription>
        </S.ProductDetails>
      </S.ProductInfo>
      <S.PriceCalculation>
        <S.QuantityPrice>
          R${' '}
          {product?.pricePaid ? formatToBrl(product?.pricePaid) : product.price}{' '}
          x {Number(product.tickets) ? product.tickets : product.tickets.length}
        </S.QuantityPrice>
        <S.TotalPrice>
          R${' '}
          {product?.pricePaid
            ? formatToBrl(product?.pricePaid)
            : (
                product.price *
                (Number(product.tickets)
                  ? product.tickets
                  : product.tickets.length)
              ).toFixed(2)}
        </S.TotalPrice>
        <S.TicketInfo>
          <S.TicketText>
            {Number(product.tickets) ? product.tickets : product.tickets.length}{' '}
            tickets
          </S.TicketText>
        </S.TicketInfo>
      </S.PriceCalculation>
      {category === 'reserved' && (
        <S.SummaryButton onClick={() => handleClickCheckout(product)}>
          Finalizar Pagamento
        </S.SummaryButton>
      )}
      <S.ViewMoreButton
        onClick={() =>
          category === 'sold' ? handleClickDetail(product.id) : null
        }
      >
        Ver todos meus números
        <BsTicket />
      </S.ViewMoreButton>
    </S.SummaryContainer>
  );
};

export default ListRaffles;
