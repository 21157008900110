import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import translate from 'translate';
import { date, mixed, object, string } from 'yup';
import PrizeGenerator from '../../../components/AddRaffle/PrizeGenerator';
import Block from '../../../components/AddRaffle/PrizeGeneratorBlock';
import PublishButton from '../../../components/AddRaffle/PublishButton';
import RaffleDetails from '../../../components/AddRaffle/RaffleDetails';
import TicketDetails from '../../../components/AddRaffle/TicketDetails';
import BackButton from '../../../components/BackButton';
import { httpServiceMiddleware } from '../../../config/httpService';
import * as S from './styles';

const CreateRaffle = () => {
  const [raffleDetails, setRaffleDetails] = useState({
    title: '',
    description: '',
    image: null,
  });

  const [ticketDetails, setTicketDetails] = useState({});
  const navigate = useNavigate();

  const schema = object({
    title: string().required('Este campo é obrigatório.'),
    description: string().required('Este campo é obrigatório.'),
    docs: mixed().test(
      '',
      'Este campo é obrigatório.',
      (value) => value.length > 0,
    ),
    initialDate: date().required('Este campo é obrigatório.'),
    finalDate: date().required('Este campo é obrigatório.'),
    quantity: string().required('Este campo é obrigatório.'),
    price: mixed().required('Este campo é obrigatório.'),
    maxQuantity: string().required('Este campo é obrigatório.'),
    minQuantity: string().required('Este campo é obrigatório.'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [prizeDetails, setPrizeDetails] = useState([]);
  const [blocks, setBlocks] = useState([
    {
      quantity: '',
      price: '',
    },
    {
      quantity: '',
      price: '',
    },
    {
      quantity: '',
      price: '',
    },
    {
      quantity: '',
      price: '',
    },
  ]);

  console.log('blocks', blocks);
  const [image, setImage] = useState(null);
  const [highlight, setHighlight] = useState(0);
  const handleUpdateTicketDetails = (newDetails) => {
    console.log('newDetails', newDetails);
    setTicketDetails(newDetails);
  };

  const maxQuantity = watch('maxQuantity');
  const minQuantity = watch('minQuantity');
  const quantityTickets = watch('quantity');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    data.tickets = prizeDetails;
    data.block = blocks.map((block) => ({
      ...block,
      price: block.price.replace(',', '.'),
    }));
    data.image = image;
    data.highlight = highlight;
    data.initialDate = moment(data.initialDate).format('YYYY-MM-DD');
    data.finalDate = moment(data.finalDate).format('YYYY-MM-DD');
    data.price = data.price.replace(',', '.');

    delete data.docs;

    try {
      setLoading(true);
      const { data: response } = await httpServiceMiddleware.post(
        '/rifas',
        data,
      );

      navigate(`/admin/create-raffle/created/${response.id}`);

      setLoading(false);
    } catch (error) {
      let errorMessage = 'Ocorreu um erro inesperado tente novamente!';
      if (error.response.data.error) {
        errorMessage = await translate(error.response.data.error, 'pt');
      }

      setLoading(false);
      Swal.fire('Oops!', errorMessage, 'error');
    }
  };

  return (
    <>
      <BackButton />
      <S.Container>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <RaffleDetails
            raffleDetails={raffleDetails}
            setRaffleDetails={setRaffleDetails}
            setImage={setImage}
            setHighlight={setHighlight}
            register={register}
            errors={errors}
          />

          <TicketDetails
            initialTicketDetails={ticketDetails}
            onTicketDetailsChange={handleUpdateTicketDetails}
            register={register}
            maxQuantity={maxQuantity}
            minQuantity={minQuantity}
            errors={errors}
            setValue={setValue}
          />

          <PrizeGenerator
            prizeDetails={prizeDetails}
            setPrizeDetails={setPrizeDetails}
            maxQuantity={quantityTickets}
            minQuantity={minQuantity}
            quantityTickets={quantityTickets}
          />

          <Block setBlocks={setBlocks} listBlocks={blocks} />

          <PublishButton type='submit' disabled={loading} />
        </S.Form>
      </S.Container>
    </>
  );
};

export default CreateRaffle;
