// src/components/OrderSummary/styles.js
import styled from 'styled-components';

export const SummaryContainer = styled.div`
  background: #ffffff;
  margin-top: 2em;
  border-radius: 21px;
  padding: 2em;
  box-shadow: 0px 5px 15px rgba(207, 208, 255, 0.31);
  width: 100%;
  margin: auto;
`;

export const SummaryTitle = styled.h2`
  font-size: 21px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

export const TicketInfo = styled.div`
  background: #91fddb;

  padding: 1em 2em;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  margin-bottom: 0.4em;
  width: 100%;
  border-radius: 12px;
  height: 45px;
  justify-content: center;
`;

export const TicketText = styled.span`
  color: #333;
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  align-items: center;
  svg {
    font-size: 22px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const ProductImage = styled.img`
  border-radius: 15px;
  margin-right: 15px;
  width: 110px;
  height: 110px;
  object-fit: cover;
`;

export const ProductDetails = styled.div``;

export const ProductName = styled.h3`
  color: #000;
  margin: 0;
  margin-bottom: 0.5em;
  font-size: 18px;
  font-weight: 600;
`;

export const ProductDescription = styled.p`
  color: #666;
  font-size: 10px;
  margin-top: 0.8em;
  margin: 0;
`;

export const Currency = styled.p`
  font-size: 10px;
`;
export const QuantityPrice = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const PriceCalculation = styled.p`
  font-size: 1.2em;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  padding-top: 20px;
`;

export const TotalPrice = styled.p`
  font-size: 1.5em;
  color: #333;
  text-align: center;
`;
