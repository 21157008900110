import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  PiArrowCircleRightDuotone,
  PiCrownSimpleDuotone,
  PiEnvelopeSimpleDuotone,
  PiPasswordDuotone,
  PiPhoneCallDuotone,
  PiShieldCheckDuotone,
  PiUserPlusDuotone,
} from 'react-icons/pi';
import InputMask from 'react-input-mask';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { httpServiceCms } from '../../../config/httpService';
import { TOKEN_NAME } from '../../../env';

import * as S from './styles';

const Register = ({ redirect, quantity, callback }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    personal_name: yup.string().required('Este campo é obrigatório.'),
    username: yup.string().required('Este campo é obrigatório.'),
    email: yup
      .string()
      .email('O campo é do tipo email.')
      .required('Este campo é obrigatório.'),
    confirm_email: yup
      .string()
      .email('O campo é do tipo email.')
      .oneOf([yup.ref('email'), null], 'Os emails não coincidem')
      .required('Este campo é obrigatório.'),
    password: yup
      .string()
      .required('Este campo é obrigatório.')
      .min(6, 'Você precisa inserir no minimo 6 caracteres'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem')
      .required('Você precisa confirmar a senha')
      .min(6, 'Você precisa inserir no minimo 6 caracteres'),
    phone: yup.string().required('Este campo é obrigatório.'),
    doc: yup
      .string()
      .required('Este campo é obrigatório.')
      .test('teste', 'Documento inválido.', (value) => validateDoc(value)),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    data.role = '3';
    try {
      setLoading(true);
      await httpServiceCms.post('/users', data);

      setLoading(false);
      await Swal.fire('Sucesso!', 'Usuário cadastrado com sucesso.', 'success');
      await handleLogin(data);
    } catch (error) {
      console.log(error);
      let errorMessage = 'Ocorreu um erro inesperado tente novamente!';

      setLoading(false);
      Swal.fire('Oops!', errorMessage, 'error');
    }
  };

  const handleLogin = async ({ email, password }) => {
    try {
      if (!email || !password) {
        throw new Error('Preencha todos os campos');
      }

      setLoading(true);
      const { data: response } = await httpServiceCms.post('/auth/local', {
        identifier: email,
        password,
      });

      localStorage.setItem(TOKEN_NAME, response.jwt);

      callback && callback();

      const toRedirect = redirect || params.get('redirect');
      const toQuantity = quantity || params.get('quantity');

      if (toRedirect) {
        navigate(`${toRedirect}?quantity=${toQuantity}`);
        return;
      }

      navigate('/account/menu');
    } catch (error) {
      Swal.fire('Oops!', 'Usuário e/ou senha incorreto.', 'warning');
    } finally {
      setLoading(false);
    }
  };

  function validateDoc(cpf) {
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let verificationDigit1 =
      remainder === 10 || remainder === 11 ? 0 : remainder;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let verificationDigit2 =
      remainder === 10 || remainder === 11 ? 0 : remainder;

    return (
      verificationDigit1 === parseInt(cpf.charAt(9)) &&
      verificationDigit2 === parseInt(cpf.charAt(10))
    );
  }

  return (
    <S.Body>
      {/* <S.HeadingBox>
        <S.Title>Faça o seu cadastro</S.Title>
        <S.SubTitle>Obtenha acesso a projetos exclusivos</S.SubTitle>
      </S.HeadingBox> */}
      <S.Forms onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <PiUserPlusDuotone />
          <S.Input
            {...register('personal_name')}
            id='personal_name'
            name='personal_name'
            className='input'
            placeholder={'Nome'}
            icon='envelope'
          />
          {errors?.personal_name && (
            <span className='error'>{errors.personal_name.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiPhoneCallDuotone />

          <InputMask
            mask='(99)9999-99999'
            maskChar={null}
            className='input'
            placeholder={'Telefone'}
            style={{ fontSize: '16px' }}
            {...register('phone')}
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </InputMask>
          {errors?.phone && (
            <span className='error'>{errors.phone.message}</span>
          )}
        </S.InputGroup>

        {/* 
        <S.Input
          {...register('username')}
          id='username'
          className='input'
          placeholder={'Usuário'}
          icon='envelope'
        />
        {errors?.username && (
          <span className='error'>{errors.username.message}</span>
        )} */}
        <S.InputGroup>
          <PiCrownSimpleDuotone />

          <InputMask
            mask='999.999.999-99'
            maskChar={null}
            className='input'
            placeholder={'CPF'}
            {...register('doc')}
            style={{ fontSize: '16px' }}
          >
            {(inputProps) => <S.Input {...inputProps} />}
          </InputMask>
          {errors?.doc && <span className='error'>{errors.doc.message}</span>}
        </S.InputGroup>

        <S.InputGroup>
          <PiEnvelopeSimpleDuotone />

          <S.Input
            {...register('email')}
            placeholder={'E-mail'}
            icon='envelope'
            className='input'
            onBlur={(e) => {
              setValue('username', e.target.value);
            }}
          />
          {errors?.email && (
            <span className='error'>{errors.email.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiEnvelopeSimpleDuotone />

          <S.Input
            {...register('confirm_email')}
            placeholder={'Confirmar E-mail'}
            icon='envelope'
            className='input'
            onBlur={(e) => {
              setValue('username', e.target.value);
            }}
          />
          {errors?.confirm_email && (
            <span className='error'>{errors.confirm_email.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiPasswordDuotone />
          <S.Input
            {...register('password')}
            placeholder={'Senha'}
            icon='lock'
            className='input'
            type='password'
          />
          {errors?.password && (
            <span className='error'>{errors.password.message}</span>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <PiShieldCheckDuotone />
          <S.Input
            {...register('confirm_password')}
            placeholder={'Confirmar senha'}
            icon='lock'
            className='input'
            type='password'
          />
          {errors?.confirm_password && (
            <span className='error'>{errors.confirm_password.message}</span>
          )}
        </S.InputGroup>

        <S.SendButton type='submit' disabled={loading}>
          Cadastrar
          <PiArrowCircleRightDuotone />
        </S.SendButton>
      </S.Forms>
    </S.Body>
  );
};

export default Register;
