import styled from 'styled-components';

// Add this for the image wrapper
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px; // Adjust the space between images as needed
  margin-bottom: 20px; // Adds some space between the images and the text below

  img {
    width: auto;
    height: auto;
    max-width: 13%;
  }
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 13%;
`;

export const Container = styled.div`
  background-color: #f9f9f9;
  padding-bottom: 180px;
  z-index: 0;
  position: relative;
`;

export const FooterLinks = styled.div`
  text-align: center;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 1.7em;
`;

export const FooterLink = styled.a`
  color: inherit;
  font-size: 16px;
  text-decoration: none;
  color: #666;

  &:hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;
