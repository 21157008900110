import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  justify-content: center;
  align-items: center;
  padding: 2em 2em;
`;

export const Title = styled.h3`
  width: 100%;
  font-size: 18px;
  color: white;
  text-align: center;
`;

export const WinnerCard = styled.div`
  border-radius: 21px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4.773px 28.636px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2.1659717559814453px);
  padding: 2em;
  width: 100%;
  max-width: 600px;
`;

export const WinnerName = styled.h2`
  font-size: 24px;
  color: #000;
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.3em;
`;

export const WinnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const WinnerDetailItem = styled.div`
  color: #666;
  font-size: 16px;
  line-height: 1.5em;
  text-align: left;
  strong {
    color: #000;
  }
`;
