import React, { useState } from 'react';
import { PiCirclesThreePlusDuotone, PiUserPlusDuotone } from 'react-icons/pi';
import styled from 'styled-components';
import Login from '../../components/User/Login';
import Register from '../../components/User/Register';

const Title = styled.h2`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  color: #707070;
  width: 100%;
  font-weight: 500;
  margin-bottom: 1em;

  svg {
    font-size: 21px;
    color: #0b0b0b;
  }
`;

const ToggleContainer = styled.div`
  text-align: center;
  display: flex;
  border-radius: 17px;
  border: 1px solid #e0e0e0;
  align-items: center;
  gap: 0em;
  width: 100%;
  margin-bottom: 1em;
`;

const ToggleButtonRight = styled.button`
  padding: 1.2em 2em;
  color: ${(props) => (props.active ? '#FFF' : '#707070')};
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#0B0B0B' : '#f2f2f2')};
  border: none;
  border-radius: 15px 0 00 15px;
  transition: all 0.3s ease;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  gap: 0.8em;

  svg {
    font-size: 1.2em;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const ToggleButtonLeft = styled.button`
  padding: 1.2em 2em;
  color: ${(props) => (props.active ? '#FFF' : '#707070')};
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#0B0B0B' : '#f2f2f2')};
  border: none;
  border-radius: 0 15px 15px 0;
  transition: all 0.3s ease;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  gap: 0.8em;

  svg {
    font-size: 1.2em;
  }

  &:hover {
    opacity: 0.9;
  }
`;
const AccountToggle = ({ redirect, quantity, callback }) => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <div>
      {/* <Title>
        {' '}
        <PiFlagDuotone />
        Faça login ou cadastro
      </Title> */}

      <ToggleContainer>
        <ToggleButtonRight onClick={() => setIsLogin(false)} active={!isLogin}>
          <PiCirclesThreePlusDuotone />
          Entrar
        </ToggleButtonRight>

        <ToggleButtonLeft onClick={() => setIsLogin(true)} active={isLogin}>
          <PiUserPlusDuotone />
          Cadastro
        </ToggleButtonLeft>
      </ToggleContainer>
      {isLogin ? (
        <Register redirect={redirect} quantity={quantity} callback={callback} />
      ) : (
        <Login callback={callback} redirect={redirect} quantity={quantity} />
      )}
    </div>
  );
};

export default AccountToggle;
