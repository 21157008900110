import styled from 'styled-components';

export const Title = styled.h5`
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 2em;
  margin-top: 1em;
`;

export const QuantityInput = styled.div`
  margin-bottom: 1em;

  input {
    width: 100%;
  padding: 1.3em;
  border-radius: 12px;
  margin-bottom: 1em;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: white;
  }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.5em;
  font-size: 15px;
`;

export const NumberInput = styled.input`
  width: 100%;
  padding: 0.5em;
  margin-bottom: 0.5em;

  border-radius: 4px;
  text-align: center;
`;

export const StepperInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

export const Stepper = styled.div`
  display: flex;
  align-items: center;
`;

export const StepperButton = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #333;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1;
  margin: 0 5px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #d4d4d4;
  }

  &:active {
    background-color: #c8c8c8;
  }
`;

export const AmountDisplay = styled.span`
  font-size: 0.8em;
  color: #666;
  padding-left: 10px;
`;

export const QuantityDropdown = styled.select`
  width: 100%;
  padding: 1.3em;
  border-radius: 12px;
  margin-bottom: 1em;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: white;

  img {
    margin-right: 1em;
  }
`;

export const OptionQuantityDropdown = styled.option`
  width: 100%;
`;

export const ValueInput = styled.input`
  width: 100%;
  padding: 1.3em;
  border-radius: 12px;
  margin-bottom: 1em;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: white;
`;
export const TicketDetailsContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  font-size: 13px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

// Wrapper for each slider input section
export const SliderInput = styled.div`
  margin-bottom: 20px;
`;

// Label for sliders
export const InputLabelSlider = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: bold;
`;

// Display for the current number value
export const NumberDisplay = styled.span`
  display: inline-block;
  padding: 4px 8px;
  margin-left: 8px;
  background-color: #ddd;
  border-radius: 4px;
`;

// Style for range sliders
export const Slider = styled.input.attrs({ type: 'range' })`
  width: 100%;
  margin: 10px 0;

  -webkit-appearance: none; /* Override default appearance */
  width: 100%; /* Full width */
  height: 15px; /* Specified height */
  border-radius: 5px;
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (optional) */
  -webkit-transition: 0.2s; /* Transition for thumb */
  transition: opacity 0.2s;

  /* Thumb Styles for Different States */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none;
    width: 45px; /* Width of the thumb */
    height: 45px; /* Height of the thumb */
    border-radius: 50%; /* Circular shape */
    background: #4caf50; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  &::-moz-range-thumb {
    width: 25px; /* Width of the thumb */
    height: 25px; /* Height of the thumb */
    border-radius: 50%; /* Circular shape */
    background: #4caf50; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
`;

// Style for numeric input fields
export const NumericInput = styled.input.attrs({ type: 'number' })`
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 8px;
  padding: 1em;
  border: none;

  box-sizing: border-box;
`;
