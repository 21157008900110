// styles.js
import styled, { css } from 'styled-components';

export const NumbersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-gap: 5px;
  max-width: 400px;
`;

export const NumberBox = styled.div`
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 10px 4px;
  text-align: center;
  font-size: 12px;
  width: 100%;
  transition: 0.3s ease;

  ${({ highlight }) =>
    highlight &&
    css`
      background-color: #0b0b0b;
      color: #fff;
    `}

  &:hover {
    scale: 1.2;
    background-color: #000;
    color: #fff;
  }
`;

export const Title = styled.div`
  padding-top: 1em;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em;
  border-top: 2px dashed #f2f2f2;
  font-size: 15px;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 0.8em;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: transparent;
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 12px;
  height: 45px;

  svg {
    font-size: 21px;
    margin-left: 0.8em;
  }
`;
