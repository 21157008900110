import BackButton from '../../components/BackButton';
import ChatContent from '../../components/ChatContent';

const Chat = () => {
  return (
    <>
      <BackButton />
      <ChatContent />
    </>
  );
};

export default Chat;
