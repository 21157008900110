// NumberList.js
import React, { useState } from 'react';
import { PiHashDuotone } from 'react-icons/pi';
import * as S from './styles';

const NumberList = ({ numbers, title }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const filteredNumbers = numbers.filter((number, index) => {
    const query = searchQuery.toLowerCase();
    return (
      number.toString().includes(query) || index.toString().includes(query)
    );
  });
  return (
    <>
      <S.SearchWrapper>
        <PiHashDuotone />
        <S.SearchInput
          type='text'
          placeholder='Encontre um número'
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </S.SearchWrapper>
      <S.NumbersGrid>
        {filteredNumbers.map((number, index) => {
          const isMatch =
            searchQuery &&
            (number.toString().includes(searchQuery) ||
              index.toString().includes(searchQuery));
          return (
            <S.NumberBox key={index} highlight={isMatch}>
              #{number}
            </S.NumberBox>
          );
        })}
      </S.NumbersGrid>
    </>
  );
};

export default NumberList;
