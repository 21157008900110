// src/components/Admin/UserMenu/index.js
import React from 'react';
import { MdOutlineSettingsInputComponent } from 'react-icons/md';
import {
  PiArrowLineLeftDuotone,
  PiCirclesThreePlusDuotone,
  PiTicketDuotone,
} from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

const newRaffleIcon = '/assets/admin/new-raffle.svg';
const settingsIcon = '/assets/admin/settings.svg';
const logoutIcon = '/assets/admin/logout.svg';

const UserMenu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/account');
  };

  return (
    <>
      <S.MenuContainer>
        <S.MenuItem onClick={() => navigate('/admin/my-raffles/')}>
          <PiTicketDuotone />
          <S.Label>Suas Rifas</S.Label>
        </S.MenuItem>
        <S.MenuItem onClick={() => navigate('/admin/create-raffle')}>
          <PiCirclesThreePlusDuotone />

          <S.Label>Nova Rifa</S.Label>
        </S.MenuItem>
        <S.MenuItem onClick={() => navigate('/admin/settings')}>
          <MdOutlineSettingsInputComponent />

          <S.Label>Configurações</S.Label>
        </S.MenuItem>
        {/* <S.MenuItem onClick={() => navigate('/admin/clients')}>
          <S.Icon src={clientsIcon} alt='Clients' />
          <S.Label>Compradores</S.Label>
        </S.MenuItem> */}
      </S.MenuContainer>
      <S.LogoutButton onClick={handleLogout}>
        <PiArrowLineLeftDuotone />
        Sair da minha conta
      </S.LogoutButton>{' '}
    </>
  );
};

export default UserMenu;
