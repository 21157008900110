import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif; // Changed to Inter
    max-width: 480px;
  }

  html {
    font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
    height: 100vh;
    margin: auto;
    max-width: 480px;
  }

  body, html {
    font-family: 'Inter', sans-serif; // Changed to Inter
    background-color: #f9f9f9;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  }

  .-no-overflow {
    overflow: hidden;
  }
`;
