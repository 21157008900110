// src/components/Admin/AddRaffle/RaffleDetails/styles.js
import styled from 'styled-components';

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
export const ToggleButton = styled.button`
  padding: 10px;
  margin-bottom: 8px;
  background-color: ${(props) =>
    props.active ? '#4CAF50' : '#f0f0f0'}; // Verde para 'Sim', cinza para 'Não'
  color: ${(props) =>
    props.active
      ? '#fff'
      : '#333'}; // Texto branco para 'Sim', preto para 'Não'
  border-radius: 12px;
  cursor: pointer;
  border: none;
  padding: 2em;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.active
        ? '#388e3c'
        : '#e0e0e0'}; // Escurece um pouco o verde para 'Sim', e o cinza para 'Não'
  }

  &:active {
    background-color: ${(props) =>
      props.active
        ? '#2e7d32'
        : '#ccc'}; // Mais escuro ainda para 'Sim', e um cinza médio para 'Não'
  }
`;
export const Title = styled.h5`
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 2em;
  margin-top: 1em;
`;

export const Input = styled.input`
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  padding: 1.5em 2em;
  border-radius: 15px;
  border: 0.915px solid #f8f8f8;
  background: var(--White, #fff);
  color: var(--Ligth-Grey, #595959);
  font-size: 16px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  line-height: normal;
`;

export const TextArea = styled.textarea`
  padding: 8px;
  margin-bottom: 8px;
  padding: 1.5em 2em;
  border-radius: 15px;
  border: 0.915px solid #f8f8f8;
  background: var(--White, #fff);
  color: var(--Ligth-Grey, #595959);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ImageUploader = styled.div`
  position: relative;
  border: 1px dashed #ccc;
  border-radius: 4px;
  text-align: center;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const ImageInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
`;

export const FileInputLabel = styled.label`
  margin-top: 10px;
  padding: 2em;
  display: flex;
  font-size: 15px;
  background-color: #f0f0f0;
  position: relative;
  color: #333;
  height: 100px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  border: 2px dashed #ccc;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #fff;
  }
`;

export const FileInput = styled.input.attrs({ type: 'file' })`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const ImagePreview = styled.img`
  max-width: 100%;
  object-fit: cover;
  max-height: 75px;
  min-width: 74px;
  margin-top: 10px;
  position: absolute;
  left: 10px;
  top: 0em;
  border-radius: 9px;
`;

export const RowInput = styled.div`
  display: flex;
  gap: 1em;
`;
