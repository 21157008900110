// src/components/PixPayment/index.js
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

const PixPayment = ({ codePix, paid }) => {
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  const navigate = useNavigate();

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(codePix);
      setIsCodeCopied(true);
      // Reset the copied status after 2 seconds, if needed.
      setTimeout(() => setIsCodeCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy PIX code:', err);
    }
  };

  const handleConfirmPayment = () => {
    navigate('/account/tickets');
  };

  return (
    <S.PixContainer>
      <S.PixCodeContainer>
        <S.PixCode>{codePix}</S.PixCode>
        <S.CopyButton onClick={handleCopyCode} copied={isCodeCopied}>
          {isCodeCopied ? 'Código Copiado!' : 'Copiar'}
        </S.CopyButton>
      </S.PixCodeContainer>{' '}
      {codePix && (
        <div
          style={{
            height: 'auto',
            margin: '2em auto',
            maxWidth: 140,
            width: '100%',
            backgroundColor: '#fff',
            padding: '1em',
            borderRadius: '12px',
          }}
        >
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={codePix}
            viewBox={`0 0 256 256`}
          />
        </div>
      )}
      <S.PixInstructions>
        Abra o app do seu banco escolha a opção PIX, como transferência.
        Selecione chave pix "copia e cola" e cole o código enviado acima. Após o
        pagamento aguarde até 5 minutos para a confirmação, caso já tenha
        efetuado o pagamento, clique no botão Já fiz o pagamento.
      </S.PixInstructions>
      <S.ConfirmButton disabled={!paid} onClick={handleConfirmPayment}>
        Já fiz o pagamento
      </S.ConfirmButton>
    </S.PixContainer>
  );
};

export default PixPayment;
