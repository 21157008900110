import axios from "axios";
import { useEffect, useState } from "react";
import * as S from "./styles";
import { PiTrophyDuotone } from "react-icons/pi";
import ModalWinner from "./modal";
const RaffleWinner = () => {
  const [winner, setWinner] = useState([]);
  useEffect(() => {
    GetWinners();
  }, []);

  const GetWinners = async () => {
    try {
      const { data } = await axios.get(
        "http://rifas-api-kelly.up.railway.app/rifas/1/winning-tickets/sold"
      );
      setWinner(data.soldWinningTickets);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  return (
    <S.Container>
      <S.WinnerTitle>Rifas Premiadas</S.WinnerTitle>
      {winner.map((ticket, index) => (
        <ModalWinner ticket={ticket} index={index} />
      ))}
    </S.Container>
  );
};

export default RaffleWinner;
