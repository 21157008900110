// src/components/ProfileSettings/index.js
import React, { useEffect, useState } from 'react';
import { AiTwotoneMail } from 'react-icons/ai';
import {
  PiArrowClockwiseDuotone,
  PiDotsNineDuotone,
  PiPasswordDuotone,
  PiUserPlusDuotone,
} from 'react-icons/pi';
import Swal from 'sweetalert2';
import { httpServiceCms } from '../../../config/httpService';
import * as S from './styles';

const ProfileSettings = () => {
  const [profile, setProfile] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
    cpf: '',
  });

  const hangleGetMe = async () => {
    const { data } = await httpServiceCms.get('/users/me');

    setProfile({
      id: data.id,
      name: data.personal_name,
      email: data.email,
      password: data.password,
      cpf: data.doc,
    });
  };

  useEffect(() => {
    hangleGetMe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      Swal.fire({
        title: 'Atualizando perfil',
        text: 'Aguarde...',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      await httpServiceCms.put(`/users/${profile.id}`, {
        personal_name: profile.name,
        email: profile.email,
        password: profile.password,
        doc: profile.cpf,
      });

      Swal.fire({
        title: 'Perfil atualizado com sucesso!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro ao atualizar perfil!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <S.SettingsContainer>
      <S.Title>Configurações do seu perfil</S.Title>

      <S.FormContainer>
        <S.InputGroup>
          <PiUserPlusDuotone />

          <S.Input
            name='name'
            placeholder='Digite o nome'
            value={profile.name}
            onChange={handleInputChange}
          />
        </S.InputGroup>
        <S.InputGroup>
          <AiTwotoneMail />

          <S.Input
            name='email'
            type='email'
            placeholder='Seu e-mail'
            value={profile.email}
            disabled
            onChange={handleInputChange}
          />
        </S.InputGroup>
        <S.InputGroup>
          <PiPasswordDuotone />
          <S.Input
            name='password'
            type='password'
            placeholder='Senha'
            value={profile.password}
            onChange={handleInputChange}
          />
        </S.InputGroup>
        <S.InputGroup>
          <PiDotsNineDuotone />

          <S.Input
            name='cpf'
            placeholder='CPF'
            value={profile.cpf}
            disabled
            onChange={handleInputChange}
          />
        </S.InputGroup>
        <S.UpdateButton onClick={handleSubmit}>
          Atualizar perfil
          <PiArrowClockwiseDuotone />
        </S.UpdateButton>
      </S.FormContainer>
    </S.SettingsContainer>
  );
};

export default ProfileSettings;
