import React from 'react';
import { PiChatTextDuotone, PiTrophyDuotone } from 'react-icons/pi';

import { PiTicketDuotone, PiUserDuotone } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './styles';

import { PiFireSimpleDuotone } from 'react-icons/pi';

const MobileMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (pathname, otherPath) =>
    location.pathname === pathname || location.pathname === otherPath;

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <S.Container>
      <S.MenuItem onClick={() => handleNavigation('/account/tickets')}>
        <S.MenuIcon isActive={isActive('/account/tickets')}>
          <PiTicketDuotone />
        </S.MenuIcon>
        Tickets
      </S.MenuItem>
      <S.MenuItem onClick={() => handleNavigation('/account')}>
        <S.MenuIcon isActive={isActive('/account', '/account/menu')}>
          <PiUserDuotone />
        </S.MenuIcon>
        Conta
      </S.MenuItem>
      <S.MenuItem onClick={() => handleNavigation('/')}>
        <S.MenuIcon isActive={isActive('/')}>
          <PiFireSimpleDuotone />
        </S.MenuIcon>
        Início
      </S.MenuItem>
      <S.MenuItem onClick={() => handleNavigation('/trophies')}>
        <S.MenuIcon isActive={isActive('/trophies')}>
          <PiTrophyDuotone />
        </S.MenuIcon>
        Ganhadores
      </S.MenuItem>
      <S.MenuItem onClick={() => handleNavigation('/chat')}>
        <S.MenuIcon isActive={isActive('/chat')}>
          <PiChatTextDuotone />
        </S.MenuIcon>
        Suporte
      </S.MenuItem>
    </S.Container>
  );
};

export default MobileMenu;
