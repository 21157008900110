// src/components/UserAccountMenu/styles.js
import styled from 'styled-components';

export const AccountMenuContainer = styled.div`
  padding: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BackToHome = styled.div`
  color: #000;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const CreditsInfo = styled.div`
  background: #e6fff8;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const MenuItem = styled.div`
  background-color: #fff; // adjust to match your theme
  width: 100%;
  height: 100px; // adjust height as needed
  border-radius: 20px; // rounded corners
  display: flex;
  padding: 0 5rem;
  font-size: 16px;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // subtle shadow
  cursor: pointer;
  gap: 1em;
  transition: transform 0.2s;

  svg {
    font-size: 28px;
  }
  &:hover {
    transform: translateY(-5px); // slight raise effect on hover
  }
`;

export const MenuText = styled.span`
  margin-left: 10px;
`;

export const MenuIcon = styled.img`
  // Adjustments can be made for specific icons if needed
`;
