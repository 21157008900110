import BackButton from '../../components/BackButton';
import WinnersCard from '../../components/WinnersCard';
import * as S from './styles';
const Trophies = () => {
  return (
    <S.Container>
      <BackButton />
      <WinnersCard />
    </S.Container>
  );
};

export default Trophies;
