import React, { useEffect, useState } from 'react';
import * as S from './styles';

const RaffleDetails = ({ setImage, register, errors, setHighlight }) => {
  const [localRaffleDetails, setLocalRaffleDetails] = useState({
    title: '',
    description: '',
    image: null,
    imagePreviewUrl: '',
  });
  const [raffleDates, setRaffleDates] = useState({
    startDate: '',
    endDate: '',
  });
  const [highlightProduct, setHighlightProduct] = useState('Sem destaque');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalRaffleDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setRaffleDates((prevDates) => ({ ...prevDates, [name]: value }));
  };

  const toggleHighlight = () => {
    setHighlightProduct((prevValue) =>
      prevValue === 'Em destaque' ? 'Sem destaque' : 'Em destaque',
    );
    setHighlight(highlightProduct === 'Em destaque' ? 1 : 0);
  };

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      setIsLoading(true); // Set loading state to true
      const file = e.target.files[0];
      const imagePreviewUrl = URL.createObjectURL(file);
      setLocalRaffleDetails((prevDetails) => ({
        ...prevDetails,
        image: file,
        imagePreviewUrl: imagePreviewUrl,
      }));

      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        setImage(base64String);
      };

      reader.readAsDataURL(file);
      setIsLoading(false); // Set loading state to false once done
    }
  };

  useEffect(() => {
    // ...existing useEffect logic...
  }, [localRaffleDetails.imagePreviewUrl]);

  return (
    <S.DetailsContainer>
      <S.Title>Detalhes da rifa 💰</S.Title>
      <S.Input
        {...register('title')}
        type='text'
        placeholder='Título da Rifa'
        value={localRaffleDetails.title}
        onChange={handleInputChange}
      />
      {errors?.title && <span className='error'>{errors.title.message}</span>}
      <S.TextArea
        {...register('description')}
        placeholder='Descrição do Prêmio'
        value={localRaffleDetails.description}
        onChange={handleInputChange}
      />
      {errors?.description && (
        <span className='error'>{errors.description.message}</span>
      )}
      <S.RowInput>
        <S.Input
          {...register('initialDate')}
          type='date'
          placeholder='Data de Início'
          onChange={handleDateChange}
        />
        <S.Input
          {...register('finalDate')}
          type='date'
          placeholder='Data de Término'
          onChange={handleDateChange}
        />
      </S.RowInput>
      <S.ToggleButton
        type='button'
        onClick={toggleHighlight}
        active={highlightProduct === 'Em destaque'}
      >
        Clique para destacar. {highlightProduct}
      </S.ToggleButton>
      <S.FileInputLabel htmlFor='file-upload'>
        {isLoading ? 'Carregando...' : 'Clique ou arraste para inserir'}

        <S.FileInput
          {...register('docs')}
          id='file-upload'
          disabled={isLoading} // Disable input while loading
          onChange={handleImageChange}
          accept='.jpg, .jpeg, .png'
        />
        {localRaffleDetails.image && (
          <S.ImagePreview
            src={localRaffleDetails.imagePreviewUrl}
            alt='Raffle Preview'
          />
        )}
      </S.FileInputLabel>
      {errors?.docs && <span className='error'>{errors.docs.message}</span>}
    </S.DetailsContainer>
  );
};

export default RaffleDetails;
