// RaffleCreated/styles.js
import styled from 'styled-components';

export const RaffleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

export const CelebrationMessage = styled.div`
  color: #777; // Gold color
  text-align: center;
  font-size: 21px;
  width: 100%;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const RaffleImage = styled.img`
  width: 70%;
  height: auto;
  border-radius: 21px;
  text-align: center;
  margin-bottom: 20px;
`;

export const RaffleTitle = styled.h1`
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const RaffleDescription = styled.p`
  color: #666;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
`;

export const RaffleDetail = styled.div`
  background: #f7f7f7;
  border-radius: 8px;

  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: space-between;
`;

export const RaffleDetailText = styled.span`
  font-size: 16px;
`;

export const ShareLinkContainer = styled.div`
  background: #e0ffe0; // Light green background
  border-radius: 15px;
  padding: 10px 15px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    scale: 1.2;
  }
`;

export const ShareLinkText = styled.span`
  color: #005500; // Dark green color
  text-decoration: none;
  border: dashed 2px transparent;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    border-color: #005500;
  }
`;

export const CopyIcon = styled.span`
  color: #005500; // Dark green color
  margin-right: 8px;
`;

export const TicketsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const TicketItem = styled.li`
  background: #f7f7f7;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TicketNumber = styled.span`
  font-size: 16px;
`;

export const PrizeText = styled.span`
  color: #666;
`;
