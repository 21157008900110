import React, { useState } from 'react';
import { PiEyeDuotone, PiEyeSlashDuotone } from 'react-icons/pi';

import {
  PiLineSegmentsDuotone,
  PiShootingStarDuotone,
  PiTicketDuotone,
  PiUserDuotone,
} from 'react-icons/pi';
import * as S from './styles';

const RaffleData = ({ detail }) => {
  const [showNumbers, setShowNumbers] = useState(true); // State to toggle visibility

  const transformToBrl = (value) => {
    return parseFloat(value).toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const mockRaffleData = [
    {
      title: 'Tickets Vendidos',
      value: detail.soldTickets,
      icon: <PiTicketDuotone />,
    },
    {
      title: 'Receita',
      value: `R$ ${transformToBrl(detail.receipt)}`,
      icon: <PiLineSegmentsDuotone />,
    },
    {
      title: 'Total de Clientes',
      value: detail.uniqueCustomers,
      icon: <PiUserDuotone />,
    },
    {
      title: 'Ticket Médio',
      value: `R$ ${transformToBrl(detail.ticketMedio)}`,
      icon: <PiShootingStarDuotone />,
    },
  ];

  return (
    <S.Container>
      <S.ToggleButton onClick={() => setShowNumbers(!showNumbers)}>
        {showNumbers ? (
          <S.ButtonShow>
            Esconder Números <PiEyeSlashDuotone />
          </S.ButtonShow>
        ) : (
          <S.ButtonShow>
            Mostrar Números <PiEyeDuotone />
          </S.ButtonShow>
        )}
      </S.ToggleButton>
      <S.DataContainer>
        {mockRaffleData.map((data, index) => (
          <S.DataBox key={index}>
            <S.DataTitle>
              {data.title}
              <S.Icon>{data.icon}</S.Icon>
            </S.DataTitle>
            <S.DataValue>{showNumbers ? data.value : '***'}</S.DataValue>
          </S.DataBox>
        ))}
      </S.DataContainer>
    </S.Container>
  );
};

export default RaffleData;
