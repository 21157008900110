import BackButton from '../../../components/BackButton';
import CreatedRaffleSucess from '../../../components/Raffle/CreatedRaffleSucess';
import * as S from './styles';

const RaffleCreated = () => {
  return (
    <S.Container>
      <BackButton />
      <CreatedRaffleSucess />
    </S.Container>
  );
};

export default RaffleCreated;
