import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton';
import * as S from './styles';

import { MdOutlineSettingsInputComponent } from 'react-icons/md';
import { PiArrowLineLeftDuotone, PiTicketDuotone } from 'react-icons/pi';

const UserAccountMenu = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/account');
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/account');
  };

  const navigateToMyTickets = () => {
    navigate('/account/tickets');
  };

  const navigateToSettings = () => {
    navigate('/account/settings');
  };

  return (
    <>
      <BackButton />
      <S.AccountMenuContainer>
        <S.MenuItem onClick={navigateToMyTickets}>
          <PiTicketDuotone />
          <S.MenuText>Suas Rifas</S.MenuText>
        </S.MenuItem>

        <S.MenuItem onClick={navigateToSettings}>
          <MdOutlineSettingsInputComponent />

          <S.MenuText>Configurações</S.MenuText>
        </S.MenuItem>

        <S.MenuItem onClick={handleLogout}>
          <PiArrowLineLeftDuotone />
          <S.MenuText>Sair da minha conta</S.MenuText>
        </S.MenuItem>
      </S.AccountMenuContainer>
    </>
  );
};

export default UserAccountMenu;
