// src/components/LastTickets/index.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_IMAGE_URL } from '../../env';
import * as S from './styles';

const LastTickets = (props) => {
  const navigate = useNavigate();
  const handleClick = async (idProduct) => {
    navigate(`/product/${idProduct}`);
  };

  const handleGetProductImage = (images) => {
    if (!images) return;

    if (images.length > 0) {
      const image = images[0].attributes.url;
      return `${APP_IMAGE_URL}${image}`;
    }
  };

  return (
    <>
      <S.LastTickets>
        <S.Header>
          <S.Title>Estão acabando, aproveite ⏰</S.Title>
        </S.Header>

        {props.products &&
          props.products.map((product) => (
            <S.TicketCard
              key={product.id}
              backgroundImage={handleGetProductImage(product.medias.data)}
              onClick={() => handleClick(product.id)}
            >
              {product.percentageSold >= 90 && (
                <S.Badge> Últimos bilhetes 🥵 </S.Badge>
              )}

              <S.TicketInfo>
                <S.Data>
                  <S.TicketTitle>{product.title}</S.TicketTitle>
                  <S.TicketDescription>
                    {product.description}
                  </S.TicketDescription>
                </S.Data>

                <S.PriceData>
                  <S.SubPrice>Por apenas</S.SubPrice>
                  <S.Price>R$ {product.price}</S.Price>
                </S.PriceData>
              </S.TicketInfo>
              <S.TicketCardBefore />
            </S.TicketCard>
          ))}
      </S.LastTickets>
    </>
  );
};

export default LastTickets;
