import { useState } from "react";
import * as S from "./styles";
import { PiTrophyDuotone } from "react-icons/pi";

const ModalWinner = ({ index, ticket }) => {
  const [showModal, setShowModal] = useState(false);
  console.log(ticket);

  const modalON = () => {
    setShowModal(true);
  };
  const modalOff = () => {
    setShowModal(false);
  };
  return (
    <>
      <S.WinnerGroup onClick={modalON}>
        <PiTrophyDuotone />
        <S.WinnerNumber key={index}>#{ticket.ticket}</S.WinnerNumber>
      </S.WinnerGroup>

      {showModal && (
        <S.Wrap onClick={modalOff}>
          <S.Modal>
            <S.Title>Nome do ganhador</S.Title>
            <S.Text>{ticket?.customer?.personal_name}</S.Text>
            <S.Title>Email</S.Title>
            <S.Text>{ticket?.customer?.email}</S.Text>
            <S.Title>Celular</S.Title>
            <S.Text>{ticket?.customer?.phone}</S.Text>
          </S.Modal>
        </S.Wrap>
      )}
    </>
  );
};

export default ModalWinner;
