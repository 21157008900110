import styled from 'styled-components';

export const HeaderInsights = styled.header`
  position: relative;
  text-align: center;
`;

export const ImagePreview = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  background-position: auto;

  top: 0;
  left: 0;
  z-index: 0; // Ensures the image stays in the background
`;

export const SubTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.5em;
  color: #000;
`;
export const Title = styled.h1`
  font-size: 18px;
  color: #000;
`;

export const Price = styled.p`
  font-size: 16px;
  text-align: right;
  color: #000;
  margin-top: 2em;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: flex-start;
  position: relative;

  padding: 1em 2em;
  text-align: left;
`;

export const ColumnLeft = styled.div`
  width: 80%;
`;

export const Column = styled.div`
  width: 30%;
`;
