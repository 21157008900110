import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-masked-input'
import * as S from './styles'; // Ensure the path matches your file structure

const TicketDetails = ({
  initialTicketDetails,
  onTicketDetailsChange,
  register,
  errors,
  setValue,
  quantityTickets,
}) => {
  const [ticketDetails, setTicketDetails] = useState(initialTicketDetails);
  const [minQuantity, setMinQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(50000);

  const handleInputChange = (e) => {
    // ... seu código de manipulação aqui ...
  };

  const handleMinSliderChange = (e) => {
    const newMinValue = String(e.target.value);
    setMinQuantity(newMinValue);
    if (newMinValue > maxQuantity) {
      setMaxQuantity(newMinValue);
    }
  };

  const handleMaxSliderChange = (e) => {
    setMaxQuantity(String(e.target.value));
  };

  useEffect(() => {
    if (typeof onTicketDetailsChange === 'function') {
      onTicketDetailsChange(ticketDetails);
    }
  }, [ticketDetails]);

  useEffect(() => {
    if (typeof onTicketDetailsChange === 'function') {
      onTicketDetailsChange(ticketDetails);
    }
  }, [ticketDetails]);

  useEffect(() => {
    if (typeof onTicketDetailsChange === 'function') {
      onTicketDetailsChange(ticketDetails);
    }
  }, [ticketDetails, onTicketDetailsChange]);

  const handleMinQuantityInputChange = (e) => {
    const newMinValue = String(e.target.value);
    setMinQuantity(newMinValue);
    setValue('minQuantity', newMinValue);
    if (newMinValue > maxQuantity) {
      setMaxQuantity(newMinValue);
      setValue('maxQuantity', newMinValue);
    }
  };

  const handleMaxQuantityInputChange = useCallback((e) => {
    const value = String(e.target.value)

    if (value < minQuantity) {
      return;
    }

    if (value > quantityTickets) {
      return;
    }
    setMaxQuantity(value);

    setValue('maxQuantity', value);
  }, [minQuantity, quantityTickets, setValue])

  return (
    <S.TicketDetailsContainer>
      <S.Title>Informações dos Tickets 🎟️</S.Title>
      <S.QuantityInput>
        <S.InputLabel>Quantidade de bilhetes</S.InputLabel>
        <S.QuantityDropdown
          {...register('quantity')}
          name='quantity'
        >
          <S.OptionQuantityDropdown value='500000'>
            500.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='1000000'>
            1.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='2000000'>
            2.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='3000000'>
            3.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='4000000'>
            4.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='5000000'>
            5.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='6000000'>
            6.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='7000000'>
            7.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='8000000'>
            8.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='9000000'>
            9.000.000
          </S.OptionQuantityDropdown>

          <S.OptionQuantityDropdown value='10000000'>
            10.000.000
          </S.OptionQuantityDropdown>
        </S.QuantityDropdown>
      </S.QuantityInput>
      {errors?.quantity && (
        <span className='error'>{errors.quantity.message}</span>
      )}
      <S.QuantityInput>
        <S.InputLabel>Valor por Ticket</S.InputLabel>

        <CurrencyInput
          {...register('price')}
          separator="."
          type='text'
          pattern='^\d*\.\d{2}$'
        />
      </S.QuantityInput>
      {errors?.ticketPrice && (
        <span className='error'>{errors.ticketPrice.message}</span>
      )}
      <S.SliderInput>
        <S.InputLabelSlider>
          Quantidade mínima{' '}
          <S.NumberDisplay>{minQuantity} ticket(s)</S.NumberDisplay>
        </S.InputLabelSlider>
        <S.Slider
          {...register('minQuantity')}
          type='range'
          min='1'
          max='10000'
          value={minQuantity}
          onChange={handleMinSliderChange}
        />
        <S.NumericInput
          type='number'
          min='1'
          max='10000'
          value={minQuantity}
          onChange={handleMinQuantityInputChange}
        />
      </S.SliderInput>

      <S.SliderInput>
        <S.InputLabelSlider>
          Quantidade máxima{' '}
          <S.NumberDisplay>{maxQuantity} ticket(s)</S.NumberDisplay>
        </S.InputLabelSlider>
        <S.Slider
          {...register('maxQuantity')}
          type='range'
          min={minQuantity}
          max='50000'
          value={maxQuantity}
          onChange={handleMaxSliderChange}
        />
        <S.NumericInput
          type='number'
          min={minQuantity}
          max='50000'
          value={maxQuantity}
          onChange={handleMaxQuantityInputChange}
        />
      </S.SliderInput>
    </S.TicketDetailsContainer>
  );
};

export default TicketDetails;
