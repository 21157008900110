import React, { useEffect, useState } from 'react';
import {
  PiArrowRightDuotone,
  PiDotsThreeDuotone,
  PiPasswordDuotone,
  PiUserPlusDuotone,
} from 'react-icons/pi';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { httpServiceCms } from '../../../config/httpService';
import { TOKEN_NAME } from '../../../env';
import * as S from './styles';

const Login = ({ redirect, quantity, callback }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token) {
      navigate('/account/menu');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = async () => {
    const { email, password } = credentials;
    try {
      if (!email || !password) {
        throw new Error('Preencha todos os campos');
      }

      localStorage.removeItem(TOKEN_NAME);

      setLoading(true);
      const { data: response } = await httpServiceCms.post('/auth/local', {
        identifier: email,
        password,
      });

      localStorage.setItem(TOKEN_NAME, response.jwt);

      const toRedirect = redirect || params.get('redirect');
      const toQuantity = quantity || params.get('quantity');

      callback && callback();
      if (toRedirect) {
        navigate(`${toRedirect}?quantity=${toQuantity}`);
        return;
      }

      const { data: user } = await httpServiceCms.get(`/users/me`, {
        params: {
          populate: 'role',
        },
      });

      if (user.role.id === 4) {
        navigate('/admin');
        return;
      }

      navigate('/account/menu');
    } catch (error) {
      Swal.fire('Oops!', 'Usuário e/ou senha incorreto.', 'warning');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <S.Body>
      <S.Forms>
        <S.InputGroup>
          <PiUserPlusDuotone />

          <S.Input
            name='email'
            value={credentials.email}
            onChange={handleChange}
            placeholder='E-mail'
            icon='envelope'
            onKeyDown={handleKeyDown}
          />
        </S.InputGroup>
        <S.InputGroup>
          <PiPasswordDuotone />

          <S.Input
            name='password'
            value={credentials.password}
            placeholder='********'
            onChange={handleChange}
            icon='lock'
            type='password'
            onKeyDown={handleKeyDown}
          />
        </S.InputGroup>
        <Link to='/forgot-password'>
          <S.ForgotPassword>
            <PiDotsThreeDuotone /> Esqueci minha senha
          </S.ForgotPassword>
        </Link>

        <S.SendButton onClick={handleLogin} disabled={loading}>
          Acessar minha conta
          <PiArrowRightDuotone />
        </S.SendButton>
        {/* <S.SendButton onClick={() => navigate('/register')} disabled={loading}>
          Cadastre
        </S.SendButton> */}
      </S.Forms>
    </S.Body>
  );
};

export default Login;
