import React, { useState } from 'react';
import * as S from './styles'; // Import styles from the styles.js file

const UserManagement = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      phone: '555-123-4567',
      ticketsBought: '10',
      lastProductBought: 'Product A',
    },
    // Add more users here
  ]);

  const deleteUser = (id) => {
    const updatedUsers = users.filter((user) => user.id !== id);
    setUsers(updatedUsers);
  };

  return (
    <S.Container>
      <h1>User List</h1>
      <S.UserTable>
        <thead>
          <tr>
            <S.TableHeader>Name</S.TableHeader>
            <S.TableHeader>Email</S.TableHeader>
            <S.TableHeader>Phone</S.TableHeader>
            <S.TableHeader>All Tickets Bought</S.TableHeader>
            <S.TableHeader>Last Product Bought</S.TableHeader>
            <S.TableHeader>Action</S.TableHeader>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <S.TableRow key={user.id}>
              <S.TableData>{user.name}</S.TableData>
              <S.TableData>{user.email}</S.TableData>
              <S.TableData>{user.phone}</S.TableData>
              <S.TableData>{user.ticketsBought}</S.TableData>
              <S.TableData>{user.lastProductBought}</S.TableData>
              <S.TableData>
                <S.DeleteButton onClick={() => deleteUser(user.id)}>
                  Delete
                </S.DeleteButton>
              </S.TableData>
            </S.TableRow>
          ))}
        </tbody>
      </S.UserTable>
    </S.Container>
  );
};

export default UserManagement;
