import styled from "styled-components";

export const WinnerNumber = styled.button`
  width: 100%;
  padding: 1.5em;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 65px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;
  background: var(--White, #fff);
  cursor: pointer;
  margin: 10px;
`;

export const WinnerGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;

export const Wrap = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

export const Modal = styled.div`
  width: 60%;
  height: 60%;

  background-color: #fff;

  border: none;
  border-radius: 20px;

  padding: 3em;
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 1em;
`;

export const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  margin: 1em;
`;
