import React from 'react';
import AccountToggle from '../../components/AccountToggle';
import BackButton from '../../components/BackButton';
import * as S from './styles';

function AccountPage() {
  return (
    <>
      <BackButton />
      <S.Container>
        <AccountToggle />
      </S.Container>
    </>
  );
}

export default AccountPage;
