import BackButton from '../../../components/BackButton';
import UserManagement from '../../../components/UserManagement';
import * as S from './styles';

const Clients = () => {
  return (
    <S.Container>
      <BackButton />
      <UserManagement />
    </S.Container>
  );
};

export default Clients;
