import styled from 'styled-components';

export const PixContainer = styled.div`
  padding: 2em;
`;

export const PixTitle = styled.h2`
  font-size: 16px;
  color: #333;
  font-weight: 600;
  text-align: center;
  margin: 0.4em 0 1em;
`;

export const PixInstructions = styled.p`
  color: #666;
  font-size: 12px;
  text-align: center;
  line-height: 1.2em;
  margin-bottom: 1em;
`;

export const PixCodeContainer = styled.div`
  background: #e7f9f2;
  position: relative;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 2em;
  margin-bottom: 5px;
  font-size: 12px;
`;

export const PixCode = styled.span`
  font-weight: bold;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CopyButton = styled.button`
  background: ${(props) => (props.copied ? '#32cd32' : '#4caf50')};
  color: #fff;

  border: none;
  right: 0.8em;
  border-radius: 9px;
  padding: 10px;
  cursor: pointer;
  width: ${(props) => (props.copied ? '180px' : '180px')};
  transition: all 0.3s ease;
`;

export const QrCode = styled.img`
  width: 100%; // Adjust based on your QR code size
  height: auto;
  margin-bottom: 15px;
`;

export const ConfirmButton = styled.button`
  background: #32cd32;
  color: #fff;
  padding: 0.8em;
  border: none;
  width: 100%;
  font-size: 15px;
  border-radius: 9px;

  cursor: pointer;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;
