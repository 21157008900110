import { PiPasswordDuotone, PiUserSwitchDuotone } from 'react-icons/pi';

import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { httpServiceCms } from '../../../config/httpService';
import * as S from './styles';

const ForgotPassword = () => {
  const [params] = useSearchParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleReset = async () => {
    const { password, passwordConfirmation } = credentials;
    console.log('passwordConfirmation', passwordConfirmation);
    console.log('password', password);
    console.log('credentials', credentials);

    const code = params.get('code');

    try {
      if (password.length < 6) {
        throw new Error('A senha deve conter no mínimo 6 caracteres');
      }

      if (password !== passwordConfirmation) {
        throw new Error('Senhas não conferem');
      }

      if (!password || !passwordConfirmation) {
        throw new Error('Preencha todos os campos');
      }

      setLoading(true);

      Swal.fire({
        title: 'Aguarde',
        text: 'Estamos atualizando a sua senha.',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      await httpServiceCms.post('/auth/reset-password', {
        code,
        password,
        passwordConfirmation,
      });

      Swal.fire(
        'Tudo certo!',
        'Agora, você pode utilizar a sua nova senha!',
        'success',
      );

      navigate('/');
    } catch (error) {
      Swal.fire(
        'Oops!',
        error?.message ||
          'Houve um erro ao atualizar a sua senha. Verifique se o código já não foi atualizado ou tente novamente.',
        'warning',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleReset();
    }
  };

  return (
    <S.Body>
      <S.HeadingBox>
        <PiPasswordDuotone />

        <S.Title>Cadastre uma nova senha</S.Title>
        <S.SubTitle>
          Digite a nova senha e em seguida confirme a mesma e lembre-se guarde
          sua senha em um local seguro.
        </S.SubTitle>
      </S.HeadingBox>
      <S.Forms>
        <S.Input
          name='password'
          value={credentials.password}
          onChange={handleChange}
          type='password'
          placeholder='Nova senha'
          onKeyDown={handleKeyDown}
        />

        <S.Input
          name='passwordConfirmation'
          value={credentials.passwordConfirmation}
          onChange={handleChange}
          placeholder='Confirmar nova senha'
          type='password'
          onKeyDown={handleKeyDown}
        />

        <S.SendButton onClick={handleReset} disabled={loading}>
          Cadastrar nova senha <PiUserSwitchDuotone />
        </S.SendButton>
      </S.Forms>
    </S.Body>
  );
};

export default ForgotPassword;
