// src/components/InstagramFollow/styles.js
import styled from 'styled-components';

export const InstagramContainer = styled.div`
  text-align: center;
  padding: 2em 0;
`;

export const InstagramHeader = styled.img`
  font-size: 24px;
  width: auto;
  height: 50px;
  margin-bottom: 1em;
`;

export const Gallery = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding-left: 3em;
  overflow: scroll;
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
  cursor: pointer;
  margin-bottom: 2em;
  border-radius: 10px;
  object-fit: cover;

  transition: all 0.3s ease;

  &:hover {
    width: 160px;
    height: 160px;
  }
`;

export const FollowText = styled.p`
  color: #262626;
  margin-bottom: 20px;
  line-height: 1.3em;
  padding: 1em 1em;
  font-size: 15px;
`;

export const FollowButton = styled.a`
  background: linear-gradient(
    to right,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  color: white;

  display: flex;
  border-radius: 39px;
  padding: 1em 2em;
  font-size: 15px;
  gap: 2em;
  text-decoration: none;
  justify-content: center;
  width: fit-content;
  margin: auto;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
    transition: all 0.3s ease;
    scale: 1.03;
    background: linear-gradient(
      to right,
      #bc1888 0%,
      #cc2366 25%,
      #dc2743 50%,
      #e6683c 75%,
      #f09433 100%
    );
  }

  svg {
    font-size: 24px;
  }
`;

export const Icon = styled.i`
  // Assuming you're using a font icon library
  margin-right: 5px;
`;

export const LoadingContainer = styled.div`
  // Add your styling here
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; // Example height
  // ... more styles as needed ...
`;
