// src/components/BackButton/index.js
import React from 'react';
import { PiArrowBendUpLeftDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const leftIcon = '/assets/account/left.svg';

const BackButtonStyled = styled.button`
  /* Add your styling here */
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #595959;
  text-decoration: none;
  font-size: 18px;
  padding: 0.8em 1.5em;

  width: 100%;
  text-align: left;

  gap: 0.8em;
  display: flex;
  align-items: center;

  transition: all 0.3s ease;

  svg {
    transition: all 0.3s ease;
    font-size: 25px;
  }
  &:hover {
    svg {
      transform: translateX(-10px);
    }
  }
`;

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This will take the user back to the last page
  };

  return (
    <BackButtonStyled onClick={goBack}>
      <PiArrowBendUpLeftDuotone />
      Retornar à página anterior
    </BackButtonStyled>
  );
};

export default BackButton;
