// RafflePrizeSet/styles.js
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3em 2em;
  background: #f7f7f7;
  width: 100%;
  border-radius: 8px;
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 1em;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;
`;
export const InputGroup = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1.5em;
  padding-left: 60px; // Make space for the icon
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 65px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;

  background: var(--White, #fff);
`;
export const ConfirmButton = styled.button`
  background-color: #4caf50; /* Green */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 40%;
  border-radius: 12px;
  padding: 1em 1em;
  gap: 1em;
  font-size: 15px;

  svg {
    font-size: 20px;
  }
  &:hover {
    background-color: #45a049;
  }
`;

export const Icon = styled.span`
  margin-left: 8px;
`;
