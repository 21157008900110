// src/components/InstagramFollow/index.js
import React, { useEffect, useState } from 'react';
import * as S from './styles';

import { PiInstagramLogoDuotone } from 'react-icons/pi';

const instagramLogo = '/assets/icons/logo-instagram.svg';

const InstagramFollow = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const username = 'parara_mas_feiiz_';

  useEffect(() => {
    // Simulate fetching data with a delay
    setTimeout(() => {
      setImages([
        {
          url: '/assets/instagram/instagram01.png',
          link: 'https://www.instagram.com/p/C3YdQwhui1L/',
        },
        {
          url: '/assets/instagram/instagram02.png',
          link: 'https://www.instagram.com/p/C3YdWUQuKB_/',
        },
        // Add more images with links as needed
      ]);
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleImageClick = (link) => {
    // Redirect to the specified URL
    window.location.href = link;
  };

  return (
    <S.InstagramContainer>
      <S.InstagramHeader src={instagramLogo} alt='Instagram Logo' />
      <S.Gallery>
        {images.map((image, index) => (
          <S.Image
            key={index}
            src={image.url}
            alt={`Instagram Image ${index + 1}`}
            onClick={() => handleImageClick(image.link)}
          />
        ))}
      </S.Gallery>
      <S.FollowText>
        Siga <b>@{username}</b> no Instagram para se manter atualizado sobre a
        vida pessoal e outros tópicos.
      </S.FollowText>
      <S.FollowButton
        href={`https://www.instagram.com/${username}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        Seguir no Instagram
        <PiInstagramLogoDuotone />
      </S.FollowButton>
    </S.InstagramContainer>
  );
};

export default InstagramFollow;
