// src/components/FAQ/styles.js
import styled from 'styled-components';

export const FAQContainer = styled.div`
  padding: 5em 3em;
`;

export const FAQTitle = styled.h2`
  font-size: 21px;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 15px;
  margin-bottom: 2em;
`;

export const QuestionList = styled.div`
  margin-bottom: 15px;
  font-size: 15px;
`;

export const Question = styled.button`
  background: #fff;
  color: #000;
  width: 100%;
  border: none;
  border-radius: 30px;
  padding: 15px;
  margin-bottom: 10px;
  padding: 1em 2em;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;

  svg {
    font-size: 24px;
    // Additional styles for the icon
  }
`;

export const Answer = styled.div`
  background: #fff;
  padding: 3em;
  font-size: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: none; // Initially hidden, can be shown with JavaScript
`;
