import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CountdownTimer from '../../../components/Checkout/CountdownTimer';
import OrderSummary from '../../../components/Checkout/OrderSummary';
import PaymentStatus from '../../../components/Checkout/PaymentStatus';
import PixPayment from '../../../components/Checkout/PixPayment';
import { httpServiceMiddleware } from '../../../config/httpService';
import { API_URL_MIDDLEWARE } from '../../../env';
import * as S from './styles';

const CheckoutScreen = () => {
  const navigate = useNavigate();
  const { reserveId } = useParams();
  const [reserve, setReserve] = useState({});
  const [product, setProduct] = useState({});
  const [countdown, setCountdown] = useState(0);
  const [timeExpired, setTimeExpired] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('unpaid');

  async function getReserve(reserveId) {
    const { data } = await httpServiceMiddleware.get(
      `/rifas/payment/verify/${reserveId}`,
    );

    if (data.error === 'Reserva não encontrada') {
      return navigate('/account/tickets');
    }
    return data;
  }
  async function getProduct(productId) {
    const { data } = await httpServiceMiddleware.get(`/rifas/${productId}`);
    return data;
  }

  function minutosParaSegundos(minutos) {
    return minutos * 60;
  }

  useEffect(() => {
    const eventSource = new EventSource(
      `${API_URL_MIDDLEWARE}rifas/sse/events`,
    );

    eventSource.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);

      if (receivedData?.reference === reserveId) {
        setPaymentStatus('paid');
        
      }
    };

    return () => {
      eventSource.close();
    };
  }, [reserveId]);

  useEffect(() => {
    if (paymentStatus === 'paid') {
      Swal.fire({
        title: 'Pagamento confirmado!',
        text: 'Aguarde o sorteio',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
      navigate('/account/tickets')
    }
  }, [paymentStatus]);

  useEffect(() => {
    Swal.close();
    const fetchData = async () => {
      Swal.fire({
        title: 'Aguarde',
        text: 'Buscando dados da reserva',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const data = await getReserve(reserveId);
      if (data?.externalId) {
        const product = await getProduct(data?.externalId);
        setReserve(data);
        setProduct(product);
      }
      Swal.close();
    };

    fetchData();
  }, [reserveId]);

  useEffect(() => {
    if (reserve.createdAd) {
      const createdTime = moment().diff(moment(reserve.createdAd), 'seconds');
      const timeRemaining = minutosParaSegundos(10) - createdTime;

      if (timeRemaining <= 0) {
        setTimeExpired(true);
      }
      setCountdown(timeRemaining);
    }
  }, [reserve]);

  if (!reserve?.createdAd) {
    return <p>Loading...</p>;
  }

  return (
    <S.CheckoutContainer>
      {!timeExpired && <PaymentStatus isConfirmed={paymentStatus === 'paid'} />}

      {paymentStatus !== 'paid' && (
        <CountdownTimer
          secondsLeftToExpire={countdown}
          totalTime={minutosParaSegundos(10)}
          setTimeExpired={setTimeExpired}
          timeExpired={timeExpired}
        />
      )}

      {!timeExpired && (
        <>
          <PixPayment
            codePix={reserve.codePix}
            paid={paymentStatus === 'paid'}
          />
          <OrderSummary product={product} reserve={reserve} />
        </>
      )}
    </S.CheckoutContainer>
  );
};

export default CheckoutScreen;
