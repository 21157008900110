import * as S from './styles';

const Credits = () => {
  return (
    <S.Container>
      <h1>Credits</h1>
    </S.Container>
  );
};

export default Credits;
