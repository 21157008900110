import React, { useState } from 'react';
import { PiCaretCircleDownDuotone } from 'react-icons/pi';
import * as S from './styles';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const mockFAQs = [
    {
      question: 'Como posso participar de uma rifa?',
      answer:
        'Para participar, escolha a rifa que deseja e selecione quantos números gostaria de comprar. Após isso, complete o pagamento para confirmar sua participação. Você receberá um e-mail de confirmação com seus números.',
    },
    {
      question: 'É seguro comprar rifas online?',
      answer:
        'Sim, é seguro. Utilizamos plataformas de pagamento confiáveis e toda a comunicação é criptografada para garantir a segurança das suas informações.',
    },
    {
      question: 'Como saberei se ganhei na rifa?',
      answer:
        'O sorteio é realizado na data informada na página da rifa e o resultado é divulgado em nosso site e redes sociais. Além disso, o vencedor é notificado por e-mail.',
    },
    {
      question: 'Existem limites de números que posso comprar?',
      answer:
        'Sim, cada rifa tem um limite de números que uma única pessoa pode comprar. Isso é para garantir que todos tenham chances justas de ganhar.',
    },
    {
      question: 'Como o prêmio é entregue?',
      answer:
        'Após o sorteio, entramos em contato com o vencedor para combinar a entrega. O prêmio pode ser enviado pelo correio ou retirado em um local específico, dependendo da natureza do prêmio e da localização do vencedor.',
    },
  ];

  return (
    <S.FAQContainer>
      <S.FAQTitle>Tire Suas Dúvidas 💭</S.FAQTitle>
      <S.Description>
        Confira as perguntas frequentes e obtenha todas as informações
        necessárias para participar das nossas rifas com confiança e segurança.
      </S.Description>
      <S.QuestionList>
        {mockFAQs.map((faq, index) => (
          <React.Fragment key={index}>
            <S.Question onClick={() => handleToggle(index)}>
              {faq.question}
              <PiCaretCircleDownDuotone />
            </S.Question>
            <S.Answer
              style={{ display: activeIndex === index ? 'block' : 'none' }}
            >
              {faq.answer}
            </S.Answer>
          </React.Fragment>
        ))}
      </S.QuestionList>
    </S.FAQContainer>
  );
};

export default FAQ;
