import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import RaffleDetails from '../../components/Raffle/RaffleDetails';
import { httpServiceMiddleware } from '../../config/httpService';
import * as S from './styles';
import Swal from 'sweetalert2';

async function getRaffleDetails(raffleId) {
  try {
    Swal.fire({
      title: 'Carregando...',
      text: 'Aguarde enquanto carregamos as rifas, esse processo pode demorar um pouco.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const { data } = await httpServiceMiddleware.get(
      `/rifas/list/customer/${raffleId}`,
    );

    Swal.close();
    return data;
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Erro ao carregar o detalhe da rifa! Tente novamente.',
    });
    
    throw new Error(error);
  }
}

const RaffleDetail = () => {
  const navigate = useNavigate();
  const { raffleId } = useParams();
  const [raffle, setRaffle] = useState();
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/account');
    }

    const fetchData = async () => {
      const raffleDetails = await getRaffleDetails(raffleId);
      console.log(raffleDetails);
      setRaffle(raffleDetails);
    };

    fetchData();
  }, []);

  return (
    <>
      <BackButton />
      <S.Container>{raffle && <RaffleDetails product={raffle} />}</S.Container>
    </>
  );
};

export default RaffleDetail;
