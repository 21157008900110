import BlankTemplate from '../templates/Blank';

import Account from '../pages/Account';
import Menu from '../pages/Account/Menu';
import UserSettings from '../pages/Account/UserSettings';
import Admin from '../pages/Admin';
import AdminSettings from '../pages/Admin/AdminSettings';
import Clients from '../pages/Admin/Clients';
import CreateRaffle from '../pages/Admin/CreateRaffle';
import MyRaffles from '../pages/Admin/MyRaffles';
import RaffleCreated from '../pages/Admin/RaffleCreated';
import RaffleInsights from '../pages/Admin/RaffleInsights';
import Chat from '../pages/Chat';
import Credits from '../pages/Credits';
import HomePage from '../pages/Homepage';
import CheckoutScreen from '../pages/Product/CheckoutScreen';
import ThankYou from '../pages/Product/CheckoutScreen/ThankYou';
import ProductPage from '../pages/Product/ProductPage';
import Projects from '../pages/Projects';
import RaffleDetail from '../pages/RaffleDetail';
import Terms from '../pages/Terms';
import Trophies from '../pages/Trophies';

import ForgotPassword from '../components/User/ForgotPassword';
import ResetPassword from '../components/User/ResetPassword';

export const routes = [
  // BUYING FLUX

  {
    path: '/product/:id',
    page: ProductPage,
    title: 'ProductPage',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/checkout/:reserveId',
    page: CheckoutScreen,
    title: 'CheckoutPage',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/checkout/thanks',
    page: ThankYou,
    title: 'ThankYou',
    isPublic: true,
    template: BlankTemplate,
  },

  // Raffles
  {
    path: '/raffles/details/:raffleId',
    page: RaffleDetail,
    title: 'Detalhes da Rifa',
    isPublic: true,
    template: BlankTemplate,
  },

  // ADMIN FLUX

  {
    path: '/admin',
    page: Admin,
    title: 'Admin',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/admin/my-raffles',
    page: MyRaffles,
    title: 'MyRaffles',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/admin/settings',
    page: AdminSettings,
    title: 'AdminSettings',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/admin/my-raffles/insights/:id',
    page: RaffleInsights,
    title: 'RaffleInsights',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/admin/create-raffle',
    page: CreateRaffle,
    title: 'CreateRaffle',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/admin/create-raffle/created/:id',
    page: RaffleCreated,
    title: 'RaffleCreated',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/admin/clients',
    page: Clients,
    title: 'Clients',
    isPublic: false,
    template: BlankTemplate,
  },

  // ACCOUNT FLUX
  {
    path: '/account',
    page: Account,
    title: 'Account',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/account/menu',
    page: Menu,
    title: 'Menu',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/account/settings',
    page: UserSettings,
    title: 'UserSettings',
    isPublic: false,
    template: BlankTemplate,
  },

  // COMPANY PAGES

  {
    path: '/credits',
    page: Credits,
    title: 'Credits',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/',
    page: HomePage,
    title: 'HomePage',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/trophies',
    page: Trophies,
    title: 'Trophies',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/chat',
    page: Chat,
    title: 'Chat',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/account/tickets',
    page: Projects,
    title: 'Projects',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/account/tickets/detail/:raflleId',
    page: Projects,
    title: 'Projects',
    isPublic: false,
    template: BlankTemplate,
  },

  {
    path: '/terms-conditions',
    page: Terms,
    title: 'Terms',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/forgot-password',
    page: ForgotPassword,
    title: 'Esqueci minha senha',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/cadastrar-nova-senha',
    page: ResetPassword,
    title: 'Esqueci minha senha',
    isPublic: true,
    template: BlankTemplate,
  },
];
