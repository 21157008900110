// RaffleData/styles.js
import styled from 'styled-components';

export const DataContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 5px;

  justify-content: center;
`;

export const DataBox = styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

export const DataTitle = styled.div`
  font-size: 13px;
  color: #4a4a4a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
`;

export const DataValue = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #2c2c2c;
`;

export const Icon = styled.span`
  font-size: 22px;
`;
export const ToggleButton = styled.button`
  padding: 1.4em;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease-in-out;
  svg {
    font-size: 20px;
  }
  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonShow = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  svg {
    font-size: 23px;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 2em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
