// HomePage.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FeaturedRaffle from '../../components/FeaturedRaffle';
import InstagramFollow from '../../components/InstagramFollow';
import LastTickets from '../../components/LastTickets';
// import WinnersCard from '../../components/WinnersCard';
import FAQ from '../../components/Faq';
import { httpServiceMiddleware } from '../../config/httpService';
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
`;

const getRifas = async () => {
  const { data } = await httpServiceMiddleware.get('/rifas');
  return data;
};

const HomePage = () => {
  const [products, setProducts] = useState([]);
  const [highlightProduct, setHighlightProduct] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const data = await getRifas();
      console.log(data);
      setHighlightProduct(data.highlights);
      setProducts(data.notHighlights);
    };

    fetchData();
  }, []);

  return (
    <HomeContainer>
      {highlightProduct?.id && <FeaturedRaffle product={highlightProduct} />}
      {!!products?.length && <LastTickets products={products} />}
      <InstagramFollow />
      <FAQ />
      {/* <WinnersCard /> */}
    </HomeContainer>
  );
};

export default HomePage;
