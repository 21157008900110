// src/components/ProfileSettings/styles.js
import styled from 'styled-components';

export const SettingsContainer = styled.div`
  display: flex;
  padding: 1.4em;
  flex-direction: column;
  gap: 00;
  border-radius: 10px;
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4em;
`;

export const Title = styled.h3`
  width: 100%;
  font-size: 21px;
  margin-bottom: 1em;
  color: black;
`;

export const InputGroup = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    font-size: 22px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 1.5em;
  padding-left: 60px; // Make space for the icon
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 65px;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #dfdfdf;

  background: var(--White, #fff);
`;

export const Icon = styled.img``;

export const UpdateButton = styled.button`
  width: 100%;
  padding: 0 2em;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  height: 65px;
  border-radius: 15px;
  border: none;
  background: var(--Green, #00bc9a);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: #45a049;
  }
`;
