// src/components/PaymentStatus/index.js
import React from 'react';
import { PiSealCheckDuotone } from 'react-icons/pi';
import * as S from './styles';

import { PiStrategyDuotone } from 'react-icons/pi';

const PaymentStatus = ({ isConfirmed }) => {
  return (
    <>
      {!isConfirmed ? (
        <S.PendingStatus>
          <S.StatusText>Aguardando o pagamento</S.StatusText>
          <PiStrategyDuotone />
        </S.PendingStatus>
      ) : (
        <S.ConfirmedStatus>
          <S.StatusText>Pagamento Confirmado</S.StatusText>
          <PiSealCheckDuotone />
        </S.ConfirmedStatus>
      )}
    </>
  );
};

export default PaymentStatus;
