import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import ListRaffles from '../../components/Raffle/ListRaffles';
import { httpServiceMiddleware } from '../../config/httpService';
import * as S from './styles';
import Swal from 'sweetalert2';

const Projects = () => {
  const navigate = useNavigate();
  const [raflles, setRaffles] = useState({ reserveds: [], solds: [] });

  async function getRaffles() {
    try {
      Swal.fire({
        title: 'Carregando...',
        text: 'Aguarde enquanto carregamos as rifas, esse processo pode demorar um pouco.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      })
      
      const { data } = await httpServiceMiddleware.get('/rifas/list/customer');

      Swal.close();
      return data;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Erro ao carregar as rifas! Tente novamente.',
      });

      throw new Error(error);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/account');
    }

    const fetchData = async () => {
      const allRaffles = await getRaffles();
      setRaffles(allRaffles);
    };

    fetchData();
  }, []);

  return (
    <>
      <BackButton />
      <S.Container>
        {raflles.reserveds &&
          raflles.reserveds.map((raffle) => (
            <ListRaffles product={raffle} category='reserved' />
          ))}
        {raflles.solds &&
          raflles.solds.map((raffle) => (
            <ListRaffles product={raffle} category='sold' />
          ))}
      </S.Container>
    </>
  );
};

export default Projects;
