// src/components/PaymentStatus/styles.js
import styled from 'styled-components';

export const StatusContainer = styled.div`
  padding: 1em;
  border-radius: 0px;
  margin: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const PendingStatus = styled(StatusContainer)`
  background: #f3ff00;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1.5em;
  border-radius: 0;
  font-size: 16px;
  margin: 0;

  svg {
    font-size: 30px;
  }
`;

export const ConfirmedStatus = styled(StatusContainer)`
  background: #2ecc71;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1.5em;
  border-radius: 0;
  font-size: 16px;
  margin: 0;
`;

export const StatusText = styled.span`
  color: #333;
  font-weight: bold;
  margin-left: 0.5em; // Adjust as needed for the icon
`;

export const TimerContainer = styled(StatusContainer)`
  background: #333;
  color: #fff;
`;

export const TimerText = styled(StatusText)`
  color: #fff;
  margin: 0;
`;

export const Icon = styled.img`
  // Assuming you're using image files for icons
`;
