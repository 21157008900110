// src/components/FeaturedRaffle/index.js
import React, { useEffect, useState } from 'react';
import { PiTicketDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
// Removed import for APP_IMAGE_URL
import * as S from './styles';
import { ShimmerEffect } from './styles';

const imageRaffle = '/assets/raffles/rifabmw.png';

const FeaturedRaffle = ({ product }) => {
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Adiciona a variável de estado aqui

  const navigate = useNavigate();

  const handleBuyButtonClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleGetProductImage = (images) => {
    if (!images) return;

    if (images.length > 0) {
      const image = images[0].attributes.url;
      setImage(image); // Directly using the image URL without APP_IMAGE_URL
      setIsLoading(false); // Define que a imagem terminou de carregar
    }
  };

  useEffect(() => {
    if (product.id) {
      handleGetProductImage(product?.medias?.data);
    }
  }, [product]);

  return (
    <>
      <S.Container>
        <S.Title>⚡ Últimos projetos criados</S.Title>
        {product && (
          <S.RaffleCard onClick={() => handleBuyButtonClick(product.id)}>
            {isLoading ? (
              <ShimmerEffect style={{ height: '340px', width: '100%' }} />
            ) : (
              <S.ProductImage
                src={imageRaffle}
                alt={product.title}
                onLoad={() => setIsLoading(false)}
              />
            )}

            <S.RaffleInfo>
              <S.RaffleInfoInline>
                <S.ColumnLeft>
                  <S.RaffleTitle>{product.title}</S.RaffleTitle>
                  <S.RaffleDescription>
                    {product.description}
                  </S.RaffleDescription>
                </S.ColumnLeft>
              </S.RaffleInfoInline>

              <S.BuyButton onClick={() => handleBuyButtonClick(product.id)}>
                Garanta agora o seu <PiTicketDuotone />
              </S.BuyButton>
            </S.RaffleInfo>
          </S.RaffleCard>
        )}
      </S.Container>
    </>
  );
};

export default FeaturedRaffle;
