import React from 'react';
import BackButton from '../../BackButton';
import * as S from './styles';
const RaffleHeader = ({ title, price, imageUrl, subtitle }) => (
  <>
    <S.ImagePreview src={imageUrl} alt='Raffle Prize' />
    <S.HeaderInsights>
      <BackButton />
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subtitle}</S.SubTitle>
      </S.Content>
    </S.HeaderInsights>
  </>
);
export default RaffleHeader;
