import React from 'react';
import * as S from './styles';

const WinnerInfo = ({ winner }) => {
  return (
    <S.Container>
      <S.Title>🎉 Informações do Ganhador</S.Title>
      <S.WinnerCard>
        <S.WinnerName>{winner.name}</S.WinnerName>
        <S.WinnerDetails>
          <S.WinnerDetailItem>
            <strong>Telefone:</strong> {winner.phone}
          </S.WinnerDetailItem>
          <S.WinnerDetailItem>
            <strong>Email:</strong> {winner.email}
          </S.WinnerDetailItem>
          <S.WinnerDetailItem>
            <strong>Endereço:</strong> {winner.address}
          </S.WinnerDetailItem>
          <S.WinnerDetailItem>
            <strong>Cota premiada:</strong> {winner.message}
          </S.WinnerDetailItem>
        </S.WinnerDetails>
      </S.WinnerCard>
    </S.Container>
  );
};

export default WinnerInfo;
