import React, { useCallback, useState } from 'react';
import * as S from './styles';

const PrizeGenerator = ({ setPrizeDetails, maxQuantity, minQuantity }) => {
  const [prizeTickets, setPrizeTickets] = useState([
    { numberticket: '', prizename: '' },
  ]);

  const addPrizeTicket = () => {
    setPrizeTickets([...prizeTickets, { numberticket: '', prizename: '' }]);

    setPrizeDetails([...prizeTickets, { numberticket: '', prizename: '' }]);
  };

  const removePrizeTicket = (index) => {
    setPrizeTickets(prizeTickets.filter((_, i) => i !== index));
    setPrizeDetails(prizeTickets.filter((_, i) => i !== index));
  };

  const handleTicketChange = (index, value) => {
    const updatedTickets = [...prizeTickets];
    updatedTickets[index].numberticket = String(value);
    setPrizeTickets(updatedTickets);
    setPrizeDetails(updatedTickets);
  };

  const handlePrizeChange = (index, value) => {
    const updatedTickets = [...prizeTickets];
    updatedTickets[index].prizename = value;
    setPrizeTickets(updatedTickets);
    setPrizeDetails(updatedTickets);
  };

  const generateRandomNumbers = useCallback(() => {
    // Calcula a diferença entre maxQuantity e minQuantity
    const quantityRange = maxQuantity - minQuantity + 1;
  
    // Verifica se o número de bilhetes excede o intervalo disponível
    if (prizeTickets.length > quantityRange) {
      alert('Número de bilhetes excede o intervalo disponível entre minQuantity e maxQuantity');
      return;
    }
  
    const existingNumbers = new Set(prizeTickets.map(ticket => ticket.numberticket));
    const randomNumbers = new Set();
  
    while (randomNumbers.size < prizeTickets.length) {
      // Gera um número aleatório dentro do intervalo especificado
      const randomNumber = Math.floor(Math.random() * (maxQuantity + 1));
      
      // Verifica se o número gerado está dentro do intervalo e não é repetido
      if (randomNumber <= maxQuantity && !existingNumbers.has(randomNumber)) {
        randomNumbers.add(randomNumber);
      }
    }
  
    const updatedTickets = prizeTickets.map((ticket, index) => ({
      ...ticket,
      numberticket: Array.from(randomNumbers)[index],
    }));
  
    setPrizeTickets(updatedTickets);
    setPrizeDetails(updatedTickets);
  }, [prizeTickets, maxQuantity, minQuantity]);
  

  return (
    <S.PrizeGeneratorContainer>
      <S.Title>Gerar bilhetes premiados 🎯</S.Title>
      {prizeTickets.map((ticket, index) => (
        <S.TicketContainer key={index}>
          <S.StepperButton onClick={() => removePrizeTicket(index)}>
            -
          </S.StepperButton>
          <S.TicketInput
            type='text'
            value={ticket.numberticket}
            onChange={(e) => handleTicketChange(index, e.target.value)}
            placeholder='Número do Ticket'
          />
          <S.TicketInput
            type='text'
            value={ticket.prizename}
            onChange={(e) => handlePrizeChange(index, e.target.value)}
            placeholder='Prêmio'
          />
        </S.TicketContainer>
      ))}
      <S.AddButton type='button' onClick={addPrizeTicket}>
        Adicionar novo ticket
      </S.AddButton>
      <S.GenerateButton type='button' onClick={generateRandomNumbers}>
        Gerar os números aleatórios
      </S.GenerateButton>
    </S.PrizeGeneratorContainer>
  );
};

export default PrizeGenerator;
