import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import RaffleData from "../../../components/Insights/RaffleData";
import RaffleHeader from "../../../components/Insights/RaffleHeader";
import RafflePrizeSet from "../../../components/Insights/RafflePrizeSet";
import WinnersCard from "../../../components/WinnersCard";
import { httpServiceMiddleware } from "../../../config/httpService";
import RaffleWinner from "../RaffleWinner";

const RaffleInsightsPage = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [winner, setWinner] = useState({});

  const handleGetDetailRifa = async (id) => {
    try {
      Swal.fire({
        title: "Buscando informações...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await httpServiceMiddleware.get(`/rifas/${id}`);

      console.log("data", data);
      setDetail(data);

      if (data?.winnerInfo) {
        setWinner(data?.winnerInfo);
      }
    } catch (error) {
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    if (id) {
      handleGetDetailRifa(id);
    }
  }, [id]);

  const getImage = (medias) => {
    if (medias?.length > 0) {
      return `${medias[0].attributes?.url}`;
    }
  };
  // Sample data to populate the component
  const mockData = {
    title: detail.title,
    subtitle: detail.description,
    price: `Por apenas R$ ${detail.price}`,
    imageUrl: getImage(detail?.medias?.data),
  };

  const extraTickets = [
    { number: "#00000000", active: false },
    { number: "#00000001", active: true },
    { number: "#00000002", active: false },
    { number: "#00000000", active: false },
    { number: "#00000001", active: true },
    { number: "#00000002", active: false },
    { number: "#00000000", active: false },
    { number: "#00000001", active: true },
    { number: "#00000002", active: false },
    { number: "#00000000", active: false },
    { number: "#00000001", active: true },
    { number: "#00000002", active: false },
    // ... other tickets
  ];

  const defineWinner = useCallback(
    async (ticket) => {
      Swal.fire({
        title: "Buscando informações...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await httpServiceMiddleware.get(
        `/rifas/drawn/ticket?rifa=${id}&ticket=${ticket}`
      );
      setWinner(data);
      Swal.close();
    },
    [id]
  );

  return (
    <>
      <RaffleHeader
        title={mockData.title}
        subtitle={mockData.subtitle}
        price={mockData.price}
        imageUrl={mockData.imageUrl}
      />

      <RaffleData detail={detail} />
      {!winner?.id && <RafflePrizeSet defineWinner={defineWinner} />}
      <WinnersCard winner={winner} />

      <RaffleWinner />
      {/* <RafflePrizeTickets tickets={extraTickets} /> */}
    </>
  );
};

export default RaffleInsightsPage;
