import styled from 'styled-components';

export const PublishButton = styled.button`
  border-radius: 18px;
  margin-top: 1em;
  border: 0.915px solid var(--Green, #00bc9a);
  background: var(--Light-Green, #defff5);
  padding: 2em 2em;
  display: flex;
  color: var(--Black-Green, #1c5946);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  &:hover {
    background-color: #00bc9a;
    color: #fff;
  }
`;

export const PublishIcon = styled.img`
  width: 24px;
  height: 24px;
`;
