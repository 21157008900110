import { useEffect, useState } from 'react';
import MyLastTicketsCreated from '../../../components/Admin/MyLastTicketsCreated';
import BackButton from '../../../components/BackButton';
import { httpServiceMiddleware } from '../../../config/httpService';
import * as S from './styles';
const getRifas = async () => {
  const { data } = await httpServiceMiddleware.get('/rifas');
  let products = [];
  if (data.notHighlights) {
    products = data.notHighlights;
  }
  if (data.highlights) {
    products.push(data.highlights);
  }

  return products;
};

const MyRaffles = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getRifas();
      setProducts(data);
    };

    fetchData();
  }, []);
  return (
    <>
      <BackButton />
      <S.Container>
        <MyLastTicketsCreated products={products} />
      </S.Container>
    </>
  );
};

export default MyRaffles;
