// src/components/UserMenu/styles.js
import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const MenuItem = styled.div`
  background-color: #fff; // adjust to match your theme
  width: 100%;
  height: 100px; // adjust height as needed
  border-radius: 20px; // rounded corners
  display: flex;
  padding: 0 5em;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // subtle shadow
  cursor: pointer;
  gap: 1em;
  transition: transform 0.2s;

  svg {
    font-size: 28px;
  }
  &:hover {
    transform: translateY(-5px); // slight raise effect on hover
  }
`;

export const Icon = styled.img`
  width: 30px; // adjust icon size as needed
  height: 30px; // adjust icon size as needed
  margin-bottom: 10px;
`;

export const Label = styled.span`
  font-size: 16px; // adjust label font size as needed
  color: #333; // label color
`;

export const LogoutButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  background-color: #f3f3f3;
  width: 100%;
  padding: 2em 5rem;
  border-radius: 12px;
  font-size: 16px; // adjust font size as needed
  font-weight: 500;
  color: #333; // button text color
  display: flex;
  align-items: center;
  gap: 2em; // space between icon and text
  display: flex;
  margin: 1em auto;
  justify-content: space-between;
  transition: all 0.3s ease;

  svg {
    font-size: 28px;
  }

  &:hover {
    color: #666; // hover color
    background-color: #fff;
  }
`;

// Add more styles as needed for the layout...
