import axios from 'axios';
import { API_URL, API_URL_MIDDLEWARE, TOKEN_NAME } from '../env';

export const httpServiceCms = axios.create({
  baseURL: API_URL,
});

// create interceptor to add token to requests
httpServiceCms.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// // create interceptor for 401 responses
httpServiceCms.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(TOKEN_NAME);
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export const httpServiceMiddleware = axios.create({
  baseURL: API_URL_MIDDLEWARE,
});

// create interceptor to add token to requests
httpServiceMiddleware.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_NAME);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// // create interceptor for 401 responses
httpServiceMiddleware.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(TOKEN_NAME);
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);
