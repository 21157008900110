import { useEffect } from 'react';
import UserMenu from '../../components/Admin/UserMenu';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
  const navigate = useNavigate()
  
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/account');
    }
  }, [])
  return (
    <S.Container>
      <UserMenu />
    </S.Container>
  );
};

export default Admin;
