import * as S from './styles';

const ThankYou = () => {
  return (
    <S.Container>
      <h1>ThankYou</h1>
    </S.Container>
  );
};

export default ThankYou;
